import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import Button from './Button';

const HeroSectionForm = () => {
  const [formValues, setFormValues] = useState({ name: '', email: '', phone: '', services: '' });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.name) errors.name = "Name is required";
    if (!formValues.email) errors.email = "Email is required";
    if (!formValues.phone) errors.phone = "Phone is required";
    if (!formValues.services) errors.services = "Services is required";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    emailjs.send('service_cu8hs0v', 'template_a67q09h', formValues, '0TEAYV1U90jtSc3Mx')
      .then(() => {
        toast.success("Your Message sent successfully!");
        setFormValues({ name: '', email: '', phone: '', services: '' });
        setFormErrors({});
      })
      .catch(() => {
        toast.error("Failed to send the message. Please try again.");
      });
  };

  return (
    <div className="w-full relative">
      <div className="container mx-auto px-8 lg:pl-[6rem] lg:pr-[4rem] lg:mb-24 mb-8 mt-8">
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center items-center gap-10 flex-wrap sm:flex-nowrap">
            {['Name', 'Email', 'Phone', 'Services'].map((label) => (
              <div key={label} className="input-container">
                <input
                  type={label === 'Email' ? 'email' : label === 'Phone' ? 'tel' : 'text'}
                  id={label.toLowerCase()}
                  name={label.toLowerCase()}
                  value={formValues[label.toLowerCase()]}
                  className={`hero-section-form ${formErrors[label.toLowerCase()] ? 'border-red-500' : ''}`}
                  required
                  onChange={handleChange}
                  placeholder=" "
                />
                <label
                  htmlFor={label.toLowerCase()}
                  className={`placeholder ${formValues[label.toLowerCase()] ? 'placeholder--active' : ''}`}
                >
                  {label}
                </label>
                {formErrors[label.toLowerCase()] && (
                  <span className="text-red-500 text-sm">{formErrors[label.toLowerCase()]}</span>
                )}
              </div>
            ))}

            <div className="input-container">
              <Button type="submit" label="Submit" link="#" onClick={handleSubmit} padding={{ top: 5, right: 20, bottom: 5, left: 20 }} />
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default HeroSectionForm;
