import {useState } from 'react';
import TestimonialSlider from './Testimonials';
import Faqs from './Faqs';
import Blog from './Blog';
import CasestudySection from './CasestudySection';
import Footer from '../navigation/Footer';
import Solutions from './Solutions';
import HeroSectionAllPage from '../pages/section/HeroSectionAllPage';
import BeforeAfterSection from '../pages/section/BeforeAfterSection';
import PartnerLogo from '../pages/section/PartnerLogo';
import ClientLogo from '../pages/section/ClientLogo';
import FunFact from '../pages/section/FunFact';
import TrustedBySection from '../pages/section/TrustedBySection';
import PPCimg from '../../assets/video/PPC-Home-GIf.gif';
import WebDevelopmentimg from '../../assets/video/Development-Home-Gif.gif';
import Analyticsimg from '../../assets/video/Analytics-Home-Gif.gif';
import PageSpeedChecker from './checker/PageSpeedChecker';
import PerformanceGraph from '../pages/section/PerformanceGraph';

function Home() {

  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => setIsMuted(!isMuted);

  return (
    <>
      <HeroSectionAllPage />

      {/* Trusted By Section */}
      <div className="w-full relative">
        <PartnerLogo />
        <div className="container mx-auto px-[1rem] lg:px-[4rem] relative z-10">
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="col-span-1"></div>
            <div className="col-span-3 text-center">
              <TrustedBySection />
            </div>
            <div className="col-span-1"></div>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div className="w-full">
        <div className="container mx-auto px-[1rem] lg:px-[4rem] mt-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "PPC Management",
                description:
                  "Our targeted PPC strategies focus on reaching high-intent customers, optimizing every campaign with in-depth keyword research and A/B testing. It’s not just about clicks — it’s about driving quality traffic that leads to sustainable growth.",
                image: PPCimg,
                bgColor: "bg-gray-100",
                textColor: "text-black",
              },
              {
                title: "Website Development",
                description:
                  "We design websites that do more than look good. By understanding your brand and customer journey, we create fast, intuitive sites that engage visitors and convert them into loyal customers.",
                image: WebDevelopmentimg,
                bgColor: "bg-blue-500",
                textColor: "text-white",
              },
              {
                title: "Analytics & Insights",
                description:
                  "Our analytics go beyond basic metrics to uncover actionable insights into your ad performance and website behavior. This empowers you to refine strategies, optimize budgets, and drive better results.",
                image: Analyticsimg,
                bgColor: "bg-yellow-500",
                textColor: "text-white",
              },
            ].map((service, index) => (
              <div
                key={index}
                className={`${service.bgColor} p-8 rounded-3xl flex flex-col justify-between ${service.textColor}`}
              >
                <div>
                  <h3 className="leading-tight mb-2 text-[22px]">{service.title}</h3>
                  <p className="text-[15px]">{service.description}</p>
                </div>
                <img src={service.image} alt={service.title} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Performance Section */}
      <div className="w-full">
        <div className="container mx-auto px-[1rem] lg:px-[4rem] pb-[6rem] lg:pb-[10rem]">
          <div className="bg-gray-100 rounded-3xl mt-8 p-6 lg:p-0">
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
              <div className="col-span-1"></div>
              <div className="col-span-3 text-center lg:mt-16 mt-8">
                <h3 className="mb-2">Performance You Can Measure</h3>
                <p>Discover how optimized strategies drove better clicks, conversions, and ROI.</p>
              </div>
              <div className="col-span-1"></div>
            </div>
            <div className="">
              <BeforeAfterSection />
            </div>
          </div>

          {/* Additional Sections */}
          <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mt-8">
            <div className="col-span-3 md:col-span-2">
              <div className="bg-yellow-400 p-8 rounded-3xl">
                <h3 className="leading-tight mb-2 text-[25px]">Delivering Value, Driving Growth</h3>
                <p>Here’s what our partners say about working with us!</p>
                <video
                  autoPlay
                  loop
                  controls
                  muted={isMuted}
                  controlsList="nodownload nofullscreen noremoteplayback"
                  className="rounded-xl mt-4"
                  onContextMenu={(e) => e.preventDefault()} // Disable right-click context menu
                >
                  <source src={require('../../assets/video/Home-Videos.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

              </div>
            </div>
            <div className="col-span-3">
              <FunFact />
              <div className="bg-gray-100 px-0 py-8 rounded-3xl gap-x-10 mt-8">
                <h3 className="px-8 text-[25px]">Proudly Partnered with These Brands</h3>
                <ClientLogo />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
            <div className="bg-gray-100 rounded-3xl">
              <div className="p-8">
                <h3 className='leading-tight mb-2'>Success Probability Calculator</h3>
                <p>
                  <span className="text-[20px] font-semibold">Purpose:</span> Engage visitors with a personalized assessment tool to estimate how successful a PPC campaign or website redesign could be for their business.
                </p>
              </div>
             <PerformanceGraph/>
            </div>
            <div className="bg-green-100 p-8 rounded-3xl">
              <h3 className='mb-2'>What’s Your Marketing Score?</h3>
              <p>Enter a URL to test the page load time, analyze it, and find bottlenecks.</p>
              <PageSpeedChecker/>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Sections */}
      <TestimonialSlider />
      <Solutions />
      <CasestudySection />
      <Faqs />
      <Blog />
      <Footer />
    </>
  );
}

export default Home;