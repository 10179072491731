import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const PageSpeedChecker = () => {
  const [url, setUrl] = useState("");
  const [result, setResult] = useState({
    performanceScore: "00 ",
    pageSize: "0.0MB ",
    loadTime: "0.0s",
    requests: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCheck = async () => {
    if (!url.trim()) {
      setError("Please enter your URL.");
      return;
    }

    setError("");
    setLoading(true);

    const apiKey = "AIzaSyA8fMXaG62S9v0LDBJkn0NI9PrG4wHMDuQ";
    const apiUrl = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(url)}&key=${apiKey}`;

    try {
      const response = await axios.get(apiUrl);

      const data = response.data;

      const performanceScore = data.lighthouseResult.categories.performance.score * 100;
      const pageSize = data.lighthouseResult.audits["total-byte-weight"].displayValue;
      const loadTime = data.lighthouseResult.audits["interactive"].displayValue;
      const requests = data.lighthouseResult.audits["network-requests"].details.items;

      setResult({
        performanceScore,
        pageSize,
        loadTime,
        requests,
      });
    } catch (error) {
      console.error("Error fetching PageSpeed data:", error.response?.data || error.message);
      setError("Failed to fetch PageSpeed data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const downloadHAR = () => {
    if (!result || !result.requests) return;

    const harData = {
      log: {
        version: "1.2",
        creator: {
          name: "PageSpeed Checker",
          version: "1.0",
        },
        entries: result.requests.map((request) => ({
          startedDateTime: new Date().toISOString(),
          time: request.responseEndTime - request.startTime,
          request: {
            method: request.requestMethod,
            url: request.url,
            headers: request.headers || [],
          },
          response: {
            status: request.statusCode,
            headers: request.responseHeaders || [],
          },
        })),
      },
    };

    const blob = new Blob([JSON.stringify(harData, null, 2)], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "pagespeed.har.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the URL after download
    URL.revokeObjectURL(url);
  };

  return (
    <div className="mt-8">
      <div className="grid grid-cols-3 gap-6 items-center">
        <div className="col-span-2">
          <input
            type="text"
            placeholder="Enter website URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="bg-white w-full h-12 p-4 rounded-2xl"
          />
        </div>
        <div>
          <button
            onClick={handleCheck}
            className="border-2 border-black px-8 py-2 rounded-full hover:bg-black hover:text-white"
          >
            {loading ? (
              <span className="loading-dots">
                Loading<span>.</span><span>.</span><span>.</span>
              </span>
            ) : (
              "Test Now"
            )}
          </button>
        </div>
      </div>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {result && (
        <div className="mt-8">
          <div className="flex justify-between items-center">
            <h4 className="font-semibold text-[25px]">Your Results:</h4>
            <button
              onClick={downloadHAR}
              className="bg-white px-4 py-2 rounded-lg hover:bg-black hover:text-white"
            >
              <span className="pr-2">
                <FontAwesomeIcon icon={faDownload} />
              </span>
              Download HAR
            </button>
          </div>
          <div className="grid grid-cols-2 mt-8 gap-8">
            <div className="bg-white rounded-2xl p-4">
              <h4 className="mb-4">Performance Score</h4>
              <h3>{result.performanceScore}</h3>
            </div>
            <div className="bg-white rounded-2xl p-4">
              <h4 className="mb-4">Page Size</h4>
              <h3 className="text-[20px]">{result.pageSize}</h3>
            </div>
            <div className="bg-white rounded-2xl p-4">
              <h4 className="mb-4">Load Time</h4>
              <h3>{result.loadTime}</h3>
            </div>
            <div className="bg-white rounded-2xl p-4">
              <h4 className="mb-4">Requests</h4>
              <h3>{result.requests.length || "0"}</h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageSpeedChecker;
