import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchData } from '../../services/apiService';
import Footer from '../navigation/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';

const IMAGE_BASE_URL = 'https://admin.ad2box.com/public/images';
const getImageUrl = (imagePath) => `${IMAGE_BASE_URL}/${imagePath}`;

const SinglePost = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await fetchData(`posts/${id}`);
        console.log(data);
        setPost(data);
      } catch (err) {
        setError('Error fetching post');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  if (loading) return <div className="text-center my-24">Loading...</div>;
  if (error) return <div className="text-center my-24">{error}</div>;

  // Parse FAQs if needed
  let faqs = [];
  if (post.faq) {
    try {
      faqs = typeof post.faq === 'string' ? JSON.parse(post.faq) : post.faq;
    } catch (err) {
      console.error('Error parsing FAQ:', err);
    }
  }

  return (
    <>
      <div className="w-full">
        <div className="container mx-auto px-[4rem] py-12">
          <div className="text-center">
            <span className="text-orange-400">
              <Link to="/">Home</Link>
            </span>
            <FontAwesomeIcon
              icon={faArrowRightToBracket}
              className="px-2 text-gray-400"
            />
            <span className="text-orange-400">
              <Link to="/articles">Articles</Link>
            </span>
            <FontAwesomeIcon
              icon={faArrowRightToBracket}
              className="px-2 text-gray-400"
            />
            <span>{post.title}</span>
            <Link>
              <p className="mt-4 text-[12px] font-semibold">Show All Category</p>
            </Link>
          </div>
          <div className="mt-4 text-center">
            <h1 className="text-[50px] leading-tight mb-8">{post.title}</h1>
            <p className="my-4">
              <b>
                <span>Raj Siddhartha</span>
              </b>{' '}
              <span> | </span>
              <span>{formatDate(post.created_at)}</span>
            </p>
            <img
              src={getImageUrl(post.image)}
              alt={post.title}
              className="w-full rounded-md shadow-md my-8"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
            <div className="col-span-2">
              {/* Render main content */}
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />

              {/* Render conclusion */}
              {post.conclusion && (
                <div className="mt-8">
                  <h3 className="mb-4">Conclusion</h3>
                  <div dangerouslySetInnerHTML={{ __html: post.conclusion }} />
                </div>
              )}

              {/* Render FAQs */}
              {faqs.length > 0 ? (
                <div className="mt-8">
                  <h3 className="mb-4">FAQs</h3>
                  {faqs.map((faq, index) => (
                    <div key={index} className="mb-4">
                      <h4 className="">{faq.title}</h4>
                      <p>{faq.content}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="mt-8">No FAQs available for this post.</p>
              )}
            </div>
            <div>
              <div className="bg-gray-200 p-4 rounded-md">
                <h2 className="text-lg font-bold">Hello</h2>
                <p>Additional Content Goes Here</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SinglePost;
