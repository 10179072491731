import React from 'react';
import Marquee from "react-fast-marquee";

// Partner Logo Image
import Partner1 from '../../../../assets/images/partners/Amazon.png';
import Partner2 from '../../../../assets/images/partners/Apple.png';
import Partner3 from '../../../../assets/images/partners/Bing.png';
import Partner4 from '../../../../assets/images/partners/Designrush.png';
import Partner5 from '../../../../assets/images/partners/Digiperform.png';
import Partner6 from '../../../../assets/images/partners/Fiverr.png';
import Partner7 from '../../../../assets/images/partners/Google.png';
import Partner8 from '../../../../assets/images/partners/HubSpot.png';
import Partner9 from '../../../../assets/images/partners/LinkedIn.png';
import Partner10 from '../../../../assets/images/partners/Meta.png';
import Partner11 from '../../../../assets/images/partners/SEMrush.png';
import Partner12 from '../../../../assets/images/partners/Shopify.png';
import Partner13 from '../../../../assets/images/partners/Sproutsocial.png';
import Partner14 from '../../../../assets/images/partners/Upwork.png';
import Partner15 from '../../../../assets/images/partners/Zoho.png';

const partners = [
  Partner1, Partner2, Partner3, Partner4, Partner5, Partner6,
  Partner7, Partner8, Partner9, Partner10, Partner11, Partner12,
  Partner13, Partner14, Partner15
];

const PartnerLogo = () => {
  return (
    <div className="container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden mt-16">
      <Marquee
        speed={50}
        pauseOnHover={true}
        gradient={false}
        className="flex items-center gap-20"
      >
        {partners.map((partner, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-[120px] lg:w-[150px] mx-8"
          >
            <img
              src={partner}
              alt={`Partner ${index + 1}`}
              className="w-full h-auto object-contain"
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default PartnerLogo;
