import React from 'react';
import { motion } from 'framer-motion';
import Client1 from '../../../../assets/images/client/AHS Premier.png';
import Client2 from '../../../../assets/images/client/Artisaire.png';
import Client3 from '../../../../assets/images/client/Backk Market.png';
import Client4 from '../../../../assets/images/client/Bringts.png';
import Client5 from '../../../../assets/images/client/Ed-Plant-Shop.png';
import Client6 from '../../../../assets/images/client/Formuland.png';
import Client7 from '../../../../assets/images/client/Goodfair.png';
import Client8 from '../../../../assets/images/client/Guniaa.png';
import Client9 from '../../../../assets/images/client/Heim Joints.png';
import Client10 from '../../../../assets/images/client/Inkarto.png';
import Client11 from '../../../../assets/images/client/Kawaii Kart.png';
import Client12 from '../../../../assets/images/client/Made.png';
import Client13 from '../../../../assets/images/client/Red-Diamond-Furniture.png';
import Client14 from '../../../../assets/images/client/Royals Essence.png';
import Client15 from '../../../../assets/images/client/Saadis Cluster.png';
import Client16 from '../../../../assets/images/client/Sensei.png';
import Client17 from '../../../../assets/images/client/Splendour Sofa.png';
import Client18 from '../../../../assets/images/client/Taskade.png';
import Client19 from '../../../../assets/images/client/The Attractive Man.png';

const partners = [
  Client1, Client2, Client3, Client4, Client5, Client6, Client7,
  Client8, Client9, Client10, Client11, Client12, Client13, Client14,
  Client15, Client16, Client17, Client18, Client19,
];

const ClientLogoLeft = () => {
  const zoomVariants = {
    animate: (index) => ({
      scale: [1, 1.2, 1], 
      transition: {
        duration: 1.5, 
        ease: "easeInOut",
        delay: index * 1.5, 
        repeat: Infinity, 
        repeatDelay: partners.length * 1.5 - (index + 1) * 1.5, 
      },
    }),
  };

  return (
    <div className="grid grid-cols-3 lg:grid-cols-4 gap-6">
      {partners.map((partner, index) => (
        <motion.div
          key={index}
          className="flex justify-center items-center bg-white p-2 rounded-md cursor-pointer"
          custom={index}
          variants={zoomVariants}
          animate="animate"
        >
          <img
            src={partner}
            alt={`Client ${index + 1}`}
            className="w-full h-auto object-contain"
          />
        </motion.div>
      ))}
    </div>
  );
};

export default ClientLogoLeft;
