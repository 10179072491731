import { useEffect, useState } from 'react';
import { fetchData } from '../../../../services/apiService';
import HeroSectionForm from '../../section/HeroSectionForm';
import BrandingServiceNav from '../section/BrandingServiceNav';

const MarketHeroSection = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetchData('heroMarketSection');
        setData(res[0] || {});
      } catch {
        console.error('Error fetching data');
      }
    };
    getData();
  }, []);

  const { title1, title2, title3, } = data;

  return (
    <>
    <div className='bg-slate-500 bg-gradient overflow-hidden'>
      <div className='text-center lg:pb-16 pb-[4rem] pt-60 block m-auto max-w-[900px] px-4 md:px-0'>
        {title1 && (
          <span className='text-[1rem] border-2 border-black rounded-full px-5 py-1 font-semibold'>{title1}</span>
        )}
        {title2 && <h1 className='my-8'>{title2}</h1>}
        {title3 && <p className='text-[18px]'>{title3}</p>}
      </div>
      <HeroSectionForm />
    </div>
 <div className='container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden'>
 <BrandingServiceNav/>
</div>
</>
  );
};

export default MarketHeroSection;
