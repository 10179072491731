import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PopupForm from '../home/PopupForm';
import { Link } from 'react-router-dom';

const changingWords = ["Epic", "Wonderful", "Connect"];

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentWord, setCurrentWord] = useState(changingWords[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prevWord) => {
        const currentIndex = changingWords.indexOf(prevWord);
        return changingWords[(currentIndex + 1) % changingWords.length];
      });
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <footer className='w-full bg-[#0c0c0c] text-[#c1c1c1] relative overflow-hidden'>
      <div className='container mx-auto px-4 lg:px-16 py-16 lg:pt-24 md:pb-32'>
        {/* Header Section */}
        <div className="text-4xl md:text-5xl font-medium cursor-pointer hover:text-orange-400 custom-hover" onClick={togglePopup}>
          <h2 className='flex items-center'>
            Let’s make
            <FontAwesomeIcon
              icon={faArrowRight}
              className="ml-2 text-orange-400 transition-transform duration-300 ease-in-out"
            />
          </h2>
          <div className='mt-[-10px]'>
            <span> Something </span>
            <span className='ticker'>{currentWord}</span>
          </div>
        </div>

        {/* Contact Information Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-16 gap-12">
          <div>
            <h4 className="mb-2 font-semibold">Speak to us</h4>
            <p><a href="tel:+918810734094" className="hover:text-orange-400">+91 8810 734 094</a></p>
            <p><a href="mailto:hello@ad2box.com" className="hover:text-orange-400">hello@ad2box.com</a></p>
          </div>
          <div>
            <h4 className="mb-2 font-semibold">India</h4>
            <p>174, Satrikh Road, Chinhat, LKO, Uttar Pradesh, India. 226028</p>
          </div>
          <div>
            <h4 className="mb-2 font-semibold">USA</h4>
            <p>2443 Sierra Nevada Road, Mammoth Lakes CA, USA. 93546</p>
          </div>
          <div>
            <h4 className="mb-2 font-semibold">Australia</h4>
            <p>73 Warren Avenue, Killingworth, New South Wales (NSW), AUS. 2278</p>
          </div>
        </div>

        {/* Footer Links Section */}
        <div className='grid grid-cols-1 md:grid-cols-2 border-t border-orange-400 mt-16 pt-8 gap-4'>
          <div className='order-2 md:order-1'>
            <p>© 2024 Ad2Box ALL RIGHTS RESERVED.</p>
          </div>
          <div className='order-1 md:order-2'>
            <ul className='flex flex-wrap gap-6 md:gap-10 justify-start md:justify-end items-center'>
              <li><Link to="/affiliate" className="hover:text-orange-400">Affiliate</Link></li>
              <li><Link to="/career" className="hover:text-orange-400">Career</Link></li>
              <li><Link to="/term-of-service" className="hover:text-orange-400">Terms of Service</Link></li>
              <li><Link to="/privacy-policy" className="hover:text-orange-400">Privacy</Link></li>
              <li><Link to="/gdrp" className="hover:text-orange-400">GDPR</Link></li>
            </ul>
          </div>
        </div>

      </div>

      <PopupForm isOpen={isOpen} togglePopup={togglePopup} />
    </footer>
  );
};

export default Footer;
