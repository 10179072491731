import React from 'react'
import PerformanceImg1 from '../../../assets/images/LinkedInBanner.webp';

const BeforeAfterSection = () => {
  return (
   <>
    <img src={PerformanceImg1} alt="" />
   </>
  )
}

export default BeforeAfterSection