import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../pages/section/Button';

const PopupForm = ({ isOpen, togglePopup }) => {
  const [formData, setFormData] = useState({
    firstName: "", lastName: "", email: "", phone: "", location: "",
    organization: "", budget: "", service: "", summary: ""
  });

  const formFields = [
    { id: "firstName", name: "firstName", label: "First Name", type: "text", placeholder: "First Name" },
    { id: "lastName", name: "lastName", label: "Last Name", type: "text", placeholder: "Last Name" },
    { id: "email", name: "email", label: "Email", type: "email", placeholder: "Email" },
    { id: "phone", name: "phone", label: "Phone No.", type: "tel", placeholder: "Phone" },
    { id: "location", name: "location", label: "Location", type: "text", placeholder: "Location" },
    { id: "organization", name: "organization", label: "Name Of Organization", type: "text", placeholder: "Organization" },
    { id: "budget", name: "budget", label: "Select Your Approximate Budget", type: "select", placeholder: "Select one..." },
    { id: "service", name: "service", label: "Select Your Services", type: "select", placeholder: "Select one..." },
    { id: "summary", name: "summary", label: "Summary of Your Project", type: "textarea", placeholder: "Tell us about your project" }
  ];

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, email, lastName, phone, location, organization, budget, service, summary } = formData;
    if (!firstName || !email || !lastName || !phone || !location || !organization || !budget || !service || !summary) {
      toast.error("Please fill in all required fields.");
      return;
    }
    emailjs.send("service_cu8hs0v", "template_a67q09h", formData, "0TEAYV1U90jtSc3Mx")
      .then(() => {
        toast.success("Your Form sent successfully!");
        setFormData({ firstName: "", lastName: "", email: "", phone: "", location: "", organization: "", budget: "", service: "", summary: "" });
      }, () => {
        toast.error("Failed to send email. Please try again.");
      });
  };

  const popupVariants = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }, exit: { opacity: 0, y: 50, transition: { duration: 0.5 } } };

  // Hide scrollbar when popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto'; // Reset on cleanup
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <motion.div initial="hidden" animate="visible" exit="exit" variants={popupVariants}
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="hidden-scrollbar bg-[#F5F4F0] p-6 rounded-3xl shadow-lg relative w-5/6 max-h-[80vh] overflow-y-auto">
          <button onClick={togglePopup} aria-label="Close popup" className="absolute top-4 right-4 text-gray-500"><FaTimes size={24} /></button>
          <div className="px-0 lg:px-[10rem] text-center">
            <h2 className='popup-gradient-text font-medium lg:mb-8 mb-4 lg:text-[60px] text-[28px]'>Let's get started</h2>
            <h4>Fill in the Form</h4>
            <form onSubmit={handleSubmit}>
              <div className="mt-10 grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-2">
                {formFields.map(({ id, name, label, type, placeholder }) => (
                  <div key={id} className={`md:col-span-${type === 'textarea' ? 2 : 1}`}>
                    <label htmlFor={id} className="block text-left font-medium leading-6 text-gray-900">{label}</label>
                    <div className="mt-2">
                      {id === "budget" ? (
                        <div className="flex flex-col">
                          <input
                            id={id}
                            name={name}
                            type="range"
                            min="1"
                            max="6"
                            step="1"
                            value={formData[name] || 1}
                            onChange={(e) => handleChange({ target: { name, value: e.target.value } })}
                            className="w-full rangeslider outline-none"
                          />
                          <div className="mt-2 text-left">
                            {["$0k", "Below $10k", "$10k - $25k", "$25k - $50k", "$50k - $75k", "$75k - $100k", "$100k+"]
                            [formData[name] - 1 || 0]}
                          </div>
                        </div>
                      ) : type === "textarea" ? (
                        <textarea
                          id={id}
                          name={name}
                          rows={4}
                          placeholder={placeholder}
                          className="block w-full border-0 border-b border-gray-400 bg-transparent py-1.5 text-gray-900 overflow-hidden"
                          value={formData[name]}
                          onChange={handleChange}
                        />
                      ) : type === "select" ? (
                        <select
                          id={id}
                          name={name}
                          className="block w-full border-0 border-b border-gray-400 bg-transparent py-1.5 text-gray-900"
                          value={formData[name]}
                          onChange={handleChange}
                        >
                          <option value="">{placeholder}</option>
                          {id === "service" && <>
                            <option value="PPC">PPC</option>
                            <option value="Website Development">Website Development</option>
                            <option value="Conversion Rate Optimization">Conversion Rate Optimization</option>
                            <option value="Branding">Branding</option>
                            <option value="Creative Solutions">Creative Solutions</option>
                            <option value="Business Consultation">Business Consultation</option>
                            <option value="Not on the List">Not on the List</option>
                          </>}
                        </select>
                      ) : (
                        <input
                          id={id}
                          name={name}
                          type={type}
                          placeholder={placeholder}
                          className="block w-full border-0 border-b border-gray-400 bg-transparent py-1.5 text-gray-900"
                          value={formData[name]}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </div>
                ))}

              </div>
              <div className="mt-8 flex justify-center col-span-1 text-black">
                <Button label="Submit" link="#" onClick={handleSubmit} padding={{ top: 5, right: 20, bottom: 5, left: 20 }} />
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <ToastContainer />
    </>
  ) : null;
};

export default PopupForm;
