import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Header from './components/navigation/Header';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/home/Home';
import AboutUs from './components/pages/AboutUs';
import SingleCaseStudy from './components/home/SingleCaseStudy';
import SinglePost from './components/home/SinglePost';
import Solutions from './components/pages/Solutions';
import CaseStudies from './components/pages/CaseStudies';
import Blog from './components/pages/Blog';
import Contact from './components/pages/Contact';
import SearchEngineOptimization from './components/pages/services/SearchEngineOptimization';
import SocialMediaMarketing from './components/pages/services/SocialMediaMarketing';
import TermsOfService from './components/pages/TermsOfService';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import GDPR from './components/pages/GDPR';
import Affiliate from './components/pages/Affiliate';
import Career from './components/pages/Career';
import MarketplaceAds from './components/pages/services/MarketplaceAds';
import DisplayAds from './components/pages/services/DisplayAds';
import Loader from './components/Loader'; // Import Loader
import WebDevelopment from './components/pages/services/WebDevelopment';
import ConversionRateOptimization from './components/pages/services/ConversionRateOptimization';
import EcommerceDevelopment from './components/pages/services/EcommerceDevelopment';
import IoT from './components/pages/services/IoT';
import LogoDesiging from './components/pages/services/LogoDesiging';
import Branding from './components/pages/services/Branding';
import BannersFlyersDesigning from './components/pages/services/BannersFlyersDesigning';
import MarketResearch from './components/pages/services/MarketResearch';

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 1000); // Simulate loading delay
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      <ScrollToTop />
      {loading && <Loader />}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/who-we-are" element={<AboutUs />} />
        <Route path="/posts/:id" element={<SinglePost />} />
        <Route path="/case-study/:id" element={<SingleCaseStudy />} />
        <Route path="solutions" element={<Solutions />} />
        <Route path="projects" element={<CaseStudies />} />
        <Route path="casedemo" element={<SingleCaseStudy />} />
        <Route path="articles" element={<Blog />} />
        <Route path="contact" element={<Contact />} />

        {/* Solutions Pages */}
        
        <Route path="solutions/seo" element={<SearchEngineOptimization />} />
        <Route path="solutions/marketing-ads" element={<MarketplaceAds />} />
        <Route path="solutions/smm" element={<SocialMediaMarketing />} />
        <Route path="solutions/display-ads" element={<DisplayAds />} />
        <Route path="solutions/web-development" element={<WebDevelopment />} />
        <Route path="solutions/conversion-rate-optimization" element={<ConversionRateOptimization />} />
        <Route path="solutions/ecommerce-development" element={<EcommerceDevelopment />} />
        <Route path="solutions/iot" element={<IoT />} />
        <Route path="solutions/logo-designing" element={<LogoDesiging />} />
        <Route path="solutions/branding" element={<Branding />} />
        <Route path="solutions/banners-flyers-designing" element={<BannersFlyersDesigning />} />
        <Route path="solutions/market-research" element={<MarketResearch />} />

        {/* End Solutions Pages */}
        
        <Route path="term-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="gdrp" element={<GDPR />} />
        <Route path="affiliate" element={<Affiliate />} />
        <Route path="career" element={<Career />} />
      </Routes>
    </>
  );
}

export default App;
