import React, { useState } from "react";
import Footer from "../navigation/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarked,
  faClock,
  faUser,
  faChevronDown,
  faChevronUp,
  faQuoteLeft,
  faQuoteRight,
  faQuestion,
  faArrowUp,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

// Values Data
const VALUES = [
  {
    icon: faQuoteLeft,
    title: "Creativity",
    description: "We’re big on fresh perspectives and unique approaches.",
  },
  {
    icon: faQuoteRight,
    title: "Collaboration",
    description: "Together, we can achieve the extraordinary.",
  },
  {
    icon: faQuestion,
    title: "Ownership",
    description: "Be the driving force behind your projects and grow with us.",
  },
  {
    icon: faQuestion,
    title: "Flexibility",
    description: "Work-life balance isn’t just a buzzword here; it’s a way of life.",
  },
];

// Open Positions Data
const OPEN_POSITIONS = [
  {
    title: "Business Development Associate (BDA)",
    description:
      "Are you a go-getter with a knack for building relationships? As a BDA, you’ll be at the forefront of our growth, connecting with potential clients and showcasing the value of our marketing solutions.",
    location: "Remote",
    type: "Full-time",
    openings: "3 Openings",
    responsibilities: [
      "Identify and approach potential clients.",
      "Build and nurture long-term client relationships.",
      "Collaborate with the team to deliver tailored marketing solutions.",
      "Meet and exceed sales targets with creativity and persistence."
    ],
  },
  {
    title: "Digital Marketing Intern",
    description:
      "Step into the exciting world of digital marketing with hands-on experience. Work alongside seasoned professionals and learn the ropes of PPC campaigns, SEO, and social media strategy.",
    location: "Remote",
    type: "Internship",
    openings: "2 Openings",
    responsibilities: [
      "Assist in executing PPC and social media campaigns.",
      "Perform keyword research and on-page SEO tasks.",
      "Analyze campaign performance and provide insights.",
      "Brainstorm and contribute creative ideas to campaigns."
    ],
  },
];

// Reusable JobAccordion Component
const JobAccordion = ({ job, isOpen, toggleAccordion }) => (
  <div
    className="border-b-2 border-gray-100 py-4 cursor-pointer"
    onClick={toggleAccordion}
  >
    <div className="grid grid-cols-1 md:grid-cols-4 items-center gap-6">
      <div className="col-span-3">
        <h3 className="text-[25px] mb-2">{job.title}</h3>
        <p>{job.description}</p>
      </div>
      <div className="flex items-center justify-end">
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} size="1x" />
      </div>
    </div>
    <div className="grid grid-cols-1 lg:grid-cols-5 items-center">
      <div className="col-span-4">
        <div className="flex gap-4 mt-4 flex-wrap">
          <span className="border border-black rounded-full p-2">
            <FontAwesomeIcon icon={faMapMarked} size="1x" className="pr-2" />
            {job.location}
          </span>
          <span className="border border-black rounded-full p-2">
            <FontAwesomeIcon icon={faClock} size="1x" className="pr-2" />
            {job.type}
          </span>
          <span className="border border-black rounded-full p-2">
            <FontAwesomeIcon icon={faUser} size="1x" className="pr-2" />
            {job.openings}
          </span>
        </div>
      </div>
      <div>
        <h4 className="text-right">
          <a href="https://forms.gle/KY1VssNNKigKtequ8" target="_blank" rel="noopener noreferrer">
            Apply
            <FontAwesomeIcon icon={faArrowUp} className="pl-2 rotate-45" />
          </a>
        </h4>
      </div>
    </div>

    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className="overflow-hidden mt-4"
    >
      <h3 className="text-[25px] mt-4">Responsibilities</h3>
      <ul className="">
        {job.responsibilities.map((responsibility, index) => (
          <li key={index} className="leading-relaxed">
            <FontAwesomeIcon icon={faArrowRight} className="pr-2 " />
            {responsibility}
          </li>
        ))}
      </ul>
    </motion.div>
  </div>
);

const Career = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <div className="w-full bg-slate-500 bg-gradient overflow-hidden">
        <div className="container mx-auto px-4 lg:px-16 overflow-hidden pt-52 lg:pt-60 pb-16 md:pb-20">
          <span className="text-base border-2 border-black rounded-full px-5 py-1 font-semibold">
            We're Hiring
          </span>
          <h1 className="py-8 ">Join Our Team at Ad2Box Marketing Solutions</h1>
          <p className="">
            At Ad2Box, we’re not just an agency; we’re a movement driving businesses toward growth and success with
            innovative marketing strategies. If you’re someone who thrives on challenges, loves brainstorming creative
            ideas, and wants to make a meaningful impact in the world of marketing, we’re looking for you!
          </p>
          <div className="m-auto text-left lg:text-center mt-12 lg:mt-24">
            <h3 className="font-semibold">What We Value</h3>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-8 gap-12">
            {VALUES.map((value, index) => (
              <div key={index}>
                <div className="flex items-center gap-2 mb-2">
                  <span className="text-orange-400">
                    <FontAwesomeIcon icon={value.icon} size="1x" />
                  </span>
                  <h3 className="text-lg font-semibold">{value.title}</h3>
                </div>
                <p className="text-base">{value.description}</p>
              </div>
            ))}
          </div>

          <div className="mt-12 lg:mt-24  border-b-2 border-gray-100 pb-12">
            <h2 className="">Open Positions</h2>
          </div>

          {/* Render Open Positions */}
          {OPEN_POSITIONS.map((job, index) => (
            <JobAccordion
              key={index}
              job={job}
              isOpen={openIndex === index}
              toggleAccordion={() => toggleAccordion(index)}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Career;
