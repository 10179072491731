import React from 'react';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faUserFriends, faUsers, faAward, faChartLine } from '@fortawesome/free-solid-svg-icons';

const CounterAbout = () => {
    const counterData = [
        { end: 3, suffix: '+', label: 'Years Strong', icon: faChartLine, bgColor: 'bg-blue-100', iconColor: 'text-blue-600' },
        { end: 200, suffix: '+', label: 'Milestone achieved', icon: faTrophy, bgColor: 'bg-yellow-100', iconColor: 'text-yellow-600' },
        { end: 300, suffix: '+', label: 'Happy Clients', icon: faUserFriends, bgColor: 'bg-green-100', iconColor: 'text-green-600' },
        { end: 25, suffix: '+', label: 'Specialists', icon: faUsers, bgColor: 'bg-purple-100', iconColor: 'text-purple-600' },
        { end: 4, suffix: '+', label: 'Awards', icon: faAward, bgColor: 'bg-red-100', iconColor: 'text-red-600' },
    ];

    const animationVariant = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    };

    return (
        <div className="bg-orange-50">
            <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:py-24 py-8 overflow-hidden">
                <div className="m-auto text-center mb-12 md:w-[60%]">
                    <h2 className="mb-4">Every Company <span className="text-orange-400">Is A Content </span>Company</h2>
                    <p>We’re on our way to become the world’s largest content company, diligently building the technological plumbing that will power all content ever created.</p>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-6">
                    {counterData.map((item, index) => (
                        <motion.div
                            key={index}
                            className="text-center"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            variants={animationVariant}
                        >
                            <div
                                className={`w-16 h-16 flex items-center justify-center mx-auto mb-4 rounded-full ${item.bgColor}`}
                            >
                                <FontAwesomeIcon
                                    icon={item.icon}
                                    className={`text-[2rem] ${item.iconColor}`}
                                />
                            </div>
                            <CountUp
                                end={item.end}
                                suffix={item.suffix}
                                className="text-[3rem] font-semibold"
                            />
                            <p className="text-[18px]">{item.label}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CounterAbout;
