import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchData, getImageUrl, getImageUrl1 } from '../../services/apiService';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import Footer from '../navigation/Footer';


const SingleCaseStudy = () => {
  const { id } = useParams(); // Extract ID from the route
  const [caseStudy, setCaseStudy] = useState(null);

  useEffect(() => {
    const fetchSingleCaseStudy = async () => {
      try {
        const response = await fetchData(`case-studies/${id}`);
        if (response.success) {
          const data = response.data;
          // Parse the `heroImg` field if it is a stringified JSON
          if (data.heroImg && typeof data.heroImg === 'string') {
            data.heroImg = JSON.parse(data.heroImg); // Convert to array
          }
          setCaseStudy(data);
        } else {
          console.error('Error:', response.message);
        }
      } catch (error) {
        console.error('Error fetching single case study:', error);
      }
    };

    fetchSingleCaseStudy();
  }, [id]);

  if (!caseStudy) {
    return null;
  }
  return (
    <>
      <div className='w-full relative bg-gradient'>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] pt-56 pb-16 overflow-hidden'>
          <div className='grid grid-cols-1 md:grid-cols-8'>
            <div className='col-span-6'>
              <h1 className='mb-4 md:mb-8'>{caseStudy.title}</h1>
              <p>
                Each project encapsulates a unique narrative, showcasing our proficiency in crafting tailored solutions
                across various industries. From pioneering tech solutions to dynamic digital strategies, our portfolio
                is a living canvas that reflects the convergence of creativity, technology, and strategic thinking.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        slidesPerView={2}
        breakpoints={{
          640: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 5 },
        }}
      >
        {caseStudy.heroImg?.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={getImageUrl1(image)}
              alt={`Case Study Image ${index}`}
              className="rounded-lg object-cover w-full h-[250px]"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='w-full relative bg-slate-200'>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <div className='grid grid-cols-1 md:grid-cols-8 gap-4'>
            <div className='col-span-3'>
              <h2>{caseStudy.heroTitle}</h2>
            </div>
            <div className='col-span-5'>
              <p>
                {caseStudy.heroContent}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full  bg-center bg-no-repeat bg-cover h-screen relative"
        style={{
          backgroundImage: caseStudy?.uspsbgImage
            ? `url(${getImageUrl1(caseStudy.uspsbgImage)})`
            : 'none',
        }}
      >
        <div className="container mx-auto px-4 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-8">
            <div className="col-span-5 md:col-start-4">
              <div className="bg-white flex justify-between py-8 rounded-lg shadow-lg relative top-[-5rem] z-10">
                <div className="border-r-2 border-gray-200 px-16 text-center">
                  <h4 className="text-orange-400 text-5xl font-bold mb-4">{caseStudy.uspsTitle1}</h4>
                  <span className="text-lg">{caseStudy.uspsContent1}</span>
                </div>
                <div className="border-r-2 border-gray-200 px-16 text-center">
                  <h4 className="text-orange-400 text-5xl font-bold mb-4">{caseStudy.uspsTitle2}</h4>
                  <span className="text-lg">{caseStudy.uspsContent2}</span>
                </div>
                <div className="px-16 text-center">
                  <h4 className="text-orange-400 text-5xl font-bold mb-4">{caseStudy.uspsTitle3}</h4>
                  <span className="text-lg">{caseStudy.uspsContent3}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-gray-100">
        <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <div className='grid grid-cols-1 md:grid-cols-8 gap-4 justify-center items-center'>
            <div className='col-span-3'>
              <h2>{caseStudy.callengeTitle}</h2>
            </div>
            <div className='col-span-5'>
              <p> {caseStudy.callengeContent} </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-white mt-16 p-8 rounded-lg">
            <div className="hover:bg-orange-400 p-8 rounded-lg group">
              <h4 className="text-orange-400 group-hover:text-white">01</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">{caseStudy.callengeTitle1}</h3>
              <p className="group-hover:text-white">{caseStudy.callengeContent1} </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group">
              <h4 className="text-orange-400 group-hover:text-white">02</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">{caseStudy.callengeTitle2}</h3>
              <p className="group-hover:text-white">
                {caseStudy.callengeContent2}
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group">
              <h4 className="text-orange-400 group-hover:text-white">03</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">{caseStudy.callengeTitle3}</h3>
              <p className="group-hover:text-white">
                {caseStudy.callengeContent3}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <div className='grid grid-cols-1 md:grid-cols-8 gap-4 items-center'>
            <div className='col-span-3'>
              <h2>{caseStudy.solutionsTitle}</h2>
            </div>
            <div className='col-span-5'>
              <p>
                {caseStudy.solutionsContent}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 bg-white mt-16 rounded-lg items-start">
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100">
              <span className="text-orange-400"><FontAwesomeIcon icon={faQuestion} size="2x" className='bg-white py-4 px-6 rounded-md' /></span>
              <h3 className="leading-10 my-4 group-hover:text-white">{caseStudy.solutionsTitle1}</h3>
              <p className="group-hover:text-white">
                {caseStudy.solutionsContent1}
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100 mt-16">
              <span className="text-orange-400"><FontAwesomeIcon icon={faQuestion} size="2x" className='bg-white py-4 px-6 rounded-md' /></span>
              <h3 className="leading-10 my-4 group-hover:text-white">{caseStudy.solutionsTitle2}</h3>
              <p className="group-hover:text-white">
                {caseStudy.solutionsContent2}
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100 mt-32">
              <span className="text-orange-400"><FontAwesomeIcon icon={faQuestion} size="2x" className='bg-white py-4 px-6 rounded-md' /></span>
              <h3 className="leading-10 my-4 group-hover:text-white">{caseStudy.solutionsTitle3}</h3>
              <p className="group-hover:text-white">
                {caseStudy.solutionsContent3}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='px-[1rem] lg:px-[4rem] lg:pt-24 md:pb-32 py-8 overflow-hidden bg-slate-100 rounded-[3rem]'>
          <div className='text-center'>
            <h2>{caseStudy.quote}</h2>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <h2>{caseStudy.processTitle}</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 bg-white mt-16 rounded-lg items-start">
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100">
              <h4 className="text-orange-400 group-hover:text-white">Week 1</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">{caseStudy.processTitle1}</h3>
              <p className="group-hover:text-white">
                {caseStudy.processContent1}
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100 ">
              <h4 className="text-orange-400 group-hover:text-white">Week 2</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">{caseStudy.processTitle2}</h3>
              <p className="group-hover:text-white">
                {caseStudy.processContent2}
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100">
              <h4 className="text-orange-400 group-hover:text-white">Week 3</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">{caseStudy.processTitle3}</h3>
              <p className="group-hover:text-white">
                {caseStudy.processContent3}
              </p>
            </div>
          </div>
          <div className="flex justify-around items-center mt-8">
            <div className="flex flex-col items-center">
              <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
              <div className="w-1 h-32 bg-gray-300"></div>
            </div>

            <div className="flex flex-col items-center">
              <div className="w-4 h-4 bg-gray-300  rounded-full"></div>
              <div className="w-1 h-32 bg-gray-300 "></div>
            </div>

            <div className="flex flex-col items-center">
              <div className="w-4 h-4 bg-gray-300  rounded-full"></div>
              <div className="w-1 h-32 bg-gray-300 "></div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:pt-16 md:pb-32 py-8 overflow-hidden'>
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className='col-span-1'></div>
            <div className='col-span-3 text-center'>
              <h2>{caseStudy.metricsTitle}</h2>
              {/* <p>Embark on a journey where every action is a catalyst for profound change, as our strategic
                approach unfolds to create a lasting impact on tasks, projects, and beyond.
              </p> */}
            </div>
            <div className='col-span-1'></div>
          </div>
          <div className='flex justify-around items-center mt-8'>
            <div className="flex flex-col items-center ">
              <h2 className='mb-2'>{caseStudy.metricsTitle1}</h2>
              <span className='text-[16px] font-semibold'>{caseStudy.metricsContent1}</span>
            </div>
            <div className="flex flex-col items-center border-r-2 border-l-2 border-gray-200 px-24">
              <h2 className='mb-2'>{caseStudy.metricsTitle2}</h2>
              <span className='text-[16px] font-semibold'>{caseStudy.metricsContent2}</span>
            </div>
            <div className="flex flex-col items-center">
              <h2 className='mb-2'>{caseStudy.metricsTitle3}</h2>
              <span className='text-[16px] font-semibold'>{caseStudy.metricsContent3}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <div className="flex gap-6">
            <div className='col-span-2'>
              <img src={getImageUrl1(caseStudy.gridImg1)} alt={caseStudy.title || 'Case Study'} className='rounded-3xl h-full' />
            </div>
            <div className='col-span-3'>
              <img src={getImageUrl1(caseStudy.gridImg2)} alt={caseStudy.title || 'Case Study'} className='rounded-3xl h-full' />
            </div>
          </div>
          <div className='flex gap-6 mt-6'>
            <div>
              <img src={getImageUrl1(caseStudy.gridImg3)} alt={caseStudy.title || 'Case Study'} className='rounded-3xl h-full' />
            </div>
            <div>
              <img src={getImageUrl1(caseStudy.gridImg4)} alt={caseStudy.title || 'Case Study'} className='rounded-3xl h-full' />
            </div>
            <div>
              <img src={getImageUrl1(caseStudy.gridImg5)} alt={caseStudy.title || 'Case Study'} className='rounded-3xl h-full' />
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative case-bg '>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:pt-24 md:pb-32 py-8 overflow-hidden z-10 relative text-white'>
          <FontAwesomeIcon icon={faQuoteLeft} size='4x' className='mb-4' />
          <p className='text-[40px] font-semibold text-white'>
            {caseStudy.clientContent}
          </p>
          <FontAwesomeIcon icon={faQuoteRight} size='4x' className='float-end mt-4	' />
          <h3 className='mt-16'>{caseStudy.clientName}</h3>
          {/* <h4>CEO at Tech Grow.</h4> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SingleCaseStudy;
