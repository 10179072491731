import React from 'react';
import { motion } from "framer-motion";
import Footer from '../../navigation/Footer';
import PPCimg from '../../../assets/video/PPC-Home-GIf.gif';
import PartnerLogo from './section/PartnerLogo';
import Technology from './section/Technology';
import WhyChooseUsImage from '../../../assets/images/case-study/Kawaii-Banner-Home.webp';
import CoreElementsImage from '../../../assets/images/stat-love-brands-768x621.webp';
import PerformanceGraph from '../section/PerformanceGraph';
import ClientLogoLeft from './section/ClientLogoLeft';
import IndustryServed from './section/IndustryServed';
import MarketHeroSection from './heroSection/MarketHeroSection';

const gridItems = [
    {
        id: 1,
        title: "Immediate Sales Impact",
        description:
            "With ad placements directly integrated into the shopping experience, customers are more likely to convert without additional steps.",
    },
    {
        id: 2,
        title: "Pay-Per-Click Efficiency",
        description:
            "You only pay for actual clicks, ensuring budget efficiency while targeting high-intent shoppers.",
    },
    {
        id: 3,
        title: "Detailed Analytics",
        description:
            "Gain insights into customer behavior, product performance, and ad efficiency to continuously optimize your campaigns.",
    },
    {
        id: 4,
        title: "Tailored to Marketplaces",
        description:
            "Ads are designed to align with the unique structure and algorithms of each marketplace, ensuring better performance.",
    },
];

const borderVariants = {
    animate: (index) => ({
        width: ["0%", "100%", "0%"],
        transition: {
            duration: 2,
            ease: "easeInOut",
            delay: index * 2,
            repeat: Infinity,
            repeatDelay: (gridItems.length - 1) * 2,
        },
    }),
};

const Step = ({ stepNumber, title, description }) => (
    <div className="grid grid-cols-3 relative">
        <div className="text-center">
            <img src={PPCimg} alt="PPC Step" className="mx-auto" />
        </div>
        <div className="flex flex-col items-center relative">
            <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">{stepNumber}</span>
            <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
        </div>
        <div className='block m-auto'>
            <h3 className="font-semibold leading-tight mb-4">{title}</h3>
            <p>{description}</p>
        </div>
    </div>
);

const MarketResearch = () => (
    <>
        <MarketHeroSection />
        <PartnerLogo />

        <div className='w-full'>
            <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:py-24 py-16 overflow-hidden'>
                <div className='w-full lg:w-[800px] m-auto text-center'>
                    <h2 className='mb-4 text-[2rem] lg:text-[3rem]'>Why Marketplace Advertising?</h2>
                    <p>Marketplace advertising allows businesses to effectively promote their products on leading
                         e-commerce platforms like Amazon, eBay, Etsy, and Walmart. With millions of customers
                          actively searching for products, marketplace ads ensure your offerings gain visibility
                           in competitive categories. It's not just about listings; it's about positioning your
                            brand where shoppers are ready to buy..</p>
                    <div className='my-12 w-full lg:w-[500px] m-auto'>
                        <img src={WhyChooseUsImage} alt="Why Choose Us" className='rounded-xl single-solution-shadow' />
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-10 mt-20">
                    {gridItems.map((item, index) => (
                        <div key={item.id} className="relative">
                            <h3 className="relative pb-4 mb-8">
                                <span>{item.id < 10 ? `0${item.id}` : item.id}</span>
                                <div className="absolute left-0 bottom-0 h-[2px] bg-gray-100 w-full"></div>
                                <motion.div
                                    className="absolute left-0 bottom-0 h-[2px] bg-black"
                                    custom={index}
                                    variants={borderVariants}
                                    animate="animate"
                                ></motion.div>
                            </h3>
                            <h4 className="text-[22px] mb-2">{item.title}</h4>
                            <p className="text-[16px]">{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='container mx-auto px-[1rem] lg:px-[4rem] pb-16 md:pb-24 overflow-hidden'>
                <div className='bg-orange-50 rounded-2xl p-8 lg:p-16'>
                    <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-20'>
                        <div>
                            <h2 className='mb-8 leading-tight text-[2rem] lg:text-[3rem]'>Core Elements of Marketplace Ad Success</h2>
                            {[
                                { title: "Product Targeting", description: "Strategically targeting individual products to increase visibility and match customer search intent." },
                                { title: "Keyword Optimization", description: "Identifying relevant search terms specific to each marketplace to drive quality traffic to your products." },
                                { title: "A+ Content", description: "Leveraging enriched content formats like enhanced product descriptions and visuals to boost credibility and engagement." },
                                { title: "Competitor Insights", description: "Analyzing competitor performance and trends to refine strategies and maintain a competitive edge." },
                            ].map((item, idx) => (
                                <div className='mb-4' key={idx}>
                                    <h4 className='mb-2'>{item.title}</h4>
                                    <p>{item.description}</p>
                                </div>
                            ))}
                        </div>
                        <div>
                            <img src={CoreElementsImage} alt="Core Elements" />
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 mt-8 gap-8'>
                    <div className='bg-gray-50 p-8 lg:p-16 rounded-2xl'>
                        <h3 className='leading-tight mb-4'>Success Probability Calculator</h3>
                        <p className='mb-8'>
                            <span className="text-[20px] font-semibold">Purpose:</span> Engage visitors with a personalized assessment tool to estimate how successful a PPC campaign or website redesign could be for their business.
                        </p>
                        <PerformanceGraph />
                    </div>
                    <div className='bg-lime-50 p-8 lg:p-16 rounded-2xl'>
                        <h3 className="leading-tight mb-4">Proudly Partnered with These Brands</h3>
                        <ClientLogoLeft />
                    </div>
                </div>
            </div>

            <div className='w-full bg-gray-50'>
                <IndustryServed />
            </div>

            <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:py-24 py-16 overflow-hidden">
                <div className="flex justify-center mb-8 lg:mb-16">
                    <h2 className='text-[2rem] lg:text-[3rem] text-center'>How It Works?</h2>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0">
                    {/* Step 1 */}
                    <div className="text-center order-last lg:order-none">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">01</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className='block m-auto text-center lg:text-left order-first lg:order-none'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]"> Platform Selection & Account Setup</h3>
                        <p>
                        We identify the marketplaces best suited for your business, set up your seller accounts,
                         and configure advertising tools to ensure optimal functionality.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0">
                    {/* Step 2 */}
                    <div className='block m-auto text-center lg:text-left'>
                        <h3 className="font-semibold  leading-tight my-4 text-[25px] lg:text-[2rem]">Product Listing Optimization</h3>
                        <p>
                        We enhance your product listings with high-quality images, persuasive descriptions, 
                        and relevant keywords to improve organic visibility and ad performance.
                        </p>
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">02</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className="text-center">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0">
                    {/* Step 3 */}
                    <div className="text-center order-last lg:order-none">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">03</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className='block m-auto text-center lg:text-left order-first lg:order-none'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]">Ad Campaign Development</h3>
                        <p>
                        We create targeted campaigns, including Sponsored Product Ads, Headline Search Ads,
                         and Display Ads, to cater to various audience segments.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0 ">
                    {/* Step 4 */}
                    <div className='block m-auto text-center lg:text-left'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]">Real-Time Performance Monitoring</h3>
                        <p>
                        Our team tracks metrics such as click-through rates (CTR), cost-per-click (CPC),
                         sales velocity, and advertising cost of sales (ACoS) to evaluate campaign effectiveness.
                        </p>
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">04</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className="text-center">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0">
                    {/* Step 5 */}
                    <div className="text-center order-last lg:order-none">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">05</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className='block m-auto text-center lg:text-left order-first lg:order-none'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]">Ongoing Optimization</h3>
                        <p>
                        We refine campaigns regularly by adjusting bids, testing creatives, and implementing 
                        data-driven changes to improve ROI.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative  mb-8 lg:mb-0">
                    {/* Step 6 */}
                    <div className='block m-auto text-center lg:text-left'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]">Retargeting & Cross-Selling</h3>
                        <p>
                        Using marketplace tools, we retarget previous customers and create cross-selling
                         opportunities to increase basket size and repeat purchases.
                        </p>
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">06</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className="text-center">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                </div>
            </div>

        </div>
        <Technology />
        <Footer />
    </>
);

export default MarketResearch;
