import React from 'react';

// Technology Icons
import Image1 from '../../../../assets/images/technology/Adobe.png';
import Image2 from '../../../../assets/images/technology/Adroll.png';
import Image3 from '../../../../assets/images/technology/Amazon ads.png';
import Image4 from '../../../../assets/images/technology/Angular Js.png';
import Image5 from '../../../../assets/images/technology/Apple Search Ads.png';
import Image6 from '../../../../assets/images/technology/Bing Ads.png';
import Image7 from '../../../../assets/images/technology/Flipkart Ads.png';
import Image8 from '../../../../assets/images/technology/GA4.png';
import Image9 from '../../../../assets/images/technology/Google ads.png';
import Image10 from '../../../../assets/images/technology/Google Merchant Center.png';
import Image11 from '../../../../assets/images/technology/GTM.png';
import Image12 from '../../../../assets/images/technology/Klaviyo.png';
import Image13 from '../../../../assets/images/technology/Laravel.png';
import Image14 from '../../../../assets/images/technology/LinkedIn Ads.png';
import Image15 from '../../../../assets/images/technology/Magento.png';
import Image16 from '../../../../assets/images/technology/Meta.png';
import Image17 from '../../../../assets/images/technology/Node Js.png';
import Image18 from '../../../../assets/images/technology/Python.png';
import Image19 from '../../../../assets/images/technology/React Js.png';
import Image20 from '../../../../assets/images/technology/Shopify.png';
import Image21 from '../../../../assets/images/technology/Sprout Social.png';
import Image22 from '../../../../assets/images/technology/Woo Commerce.png';
import Image23 from '../../../../assets/images/technology/WordPress.png';
import Image24 from '../../../../assets/images/technology/Zapier.png';

const Technology = () => {
  // Create an array of image sources
  const technologyImages = [
    Image1, Image2, Image3, Image4, Image5, Image6,
    Image7, Image8, Image9, Image10, Image11, Image12,
    Image13, Image14, Image15, Image16, Image17, Image18,
    Image19, Image20, Image21, Image22, Image23, Image24,
  ];

  return (
    <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:pb-24 pb-16 overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-20">
        <div>
          <h2 className="text-[2rem] lg:text-[3rem]">Next Level Ad2Box Use Technologies</h2>
        </div>
        <div>
          <p className="text-[20px]">
            Boost productivity with more than 70 integrations that fold right into your workflow.
            Boost productivity with more than 70 integrations that fold right into your workflow.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-8 mt-8 justify-center gap-6">
        {technologyImages.map((image, index) => (
          <div key={index} className="technology-icon">
            <img src={image} alt={`Technology ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Technology;
