import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Modal from 'react-modal';
import { FaPlay, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Team from './section/Team';
import Footer from '../navigation/Footer';
import AboutHeroSection from './section/AboutHeroSection';
import CounterAbout from './section/CounterAbout';

Modal.setAppElement('#root');

const ImageSlider = () => {
  const images = [
    'https://res.cloudinary.com/dannpkzcu/image/upload/v1727549648/nt0eNqt8P9k-1024x576_ycd6op.jpg',
    'https://res.cloudinary.com/dannpkzcu/image/upload/v1727549648/OBV5XREI-ig-1024x683_dzms4h.jpg',
    'https://res.cloudinary.com/dannpkzcu/image/upload/v1727549648/pexels-kubrakuzu-20068318-683x1024_pury4h.jpg',
    'https://res.cloudinary.com/dannpkzcu/image/upload/v1727549649/8IRUwJkbwEA-819x1024_uet1dh.jpg',
  ];

  return (
    <Swiper spaceBetween={30} slidesPerView={2} breakpoints={{ 640: { slidesPerView: 3 } }} style={{ height: 'auto' }}>
      {images.map((image, index) => (
        <SwiperSlide key={index} style={{ width: index < 2 ? '700px' : 'auto' }}>
          <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: '451px', objectFit: 'cover', borderRadius: '8px' }} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const borderVariants = { hidden: { width: '0%' }, visible: { width: '100%' } };
  const itemVariants = { hidden: { opacity: 0, y: 20 }, visible: { opacity: 1, y: 0 } };

  const renderContent = (title, description, number) => (
    <motion.div className='border-b border-black py-6' initial="hidden" animate="visible" variants={itemVariants} transition={{ duration: 0.5 }}>
      <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
        <div className="col-span-3 flex items-center justify-center md:justify-start">
          <h3>{title}</h3>
        </div>
        <div className="col-span-3 flex items-center justify-center">
          <p className="text-center md:text-left">{description}</p>
        </div>
        <div className="col-span-2 flex items-center justify-center md:justify-end">
          <h2>{number}</h2>
        </div>
      </div>
    </motion.div>
  );

  return (
    <>
     <AboutHeroSection/>
      <motion.div className="w-full" initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-16 my-8 overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="col-span-6">
              <h1 className="lg:text-[4rem] text-[2rem] popup-gradient-text">"Believe in Growth, Achieve the Extraordinary"</h1>
            </div>
          </div>
        </div>
      </motion.div>

      <ImageSlider />
     
      <motion.div className="w-full" initial={{ opacity: 0, scale: 0.9 }} whileInView={{ opacity: 1, scale: 1 }} transition={{ duration: 0.8 }} viewport={{ once: true }}>
        <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-24 my-8 overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
            <div className="col-span-2"><h4>About Ad2box</h4></div>
            <div className="col-span-4">
              <p className="text-[25px] font-medium">We solve complex challenges with strategic digital solutions. Our passion drives us to deliver impactful results that empower our clients to succeed.</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-14">
                <div>
                  <h4 className="mb-4"> Client-Centric Approach</h4>
                  <p className="text-[14px]">We place your business goals at the forefront, truly listening to your needs and crafting personalized strategies that align with your vision. Every solution we deliver is tailored to not just meet expectations but exceed them, ensuring sustainable growth and value.</p>
                </div>
                <div>
                  <h4 className="mb-4"> Industry Expertise You Can Trust</h4>
                  <p className="text-[14px]">We understand your unique challenges, going beyond surface-level interactions to uncover what truly matters to your business. Our experts leverage technology and insightful solutions to deliver measurable outcomes, fostering trust and long-term partnerships.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <div className="w-full relative">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }} className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-16 my-8 overflow-hidden">
          <video autoPlay muted loop playsInline className="w-full h-[50vh] md:h-[100vh] object-cover" style={{ borderRadius: '24px' }}>
            <source src="https://adtobox.com/wp-content/uploads/2023/12/WhatsApp-Video-2023-11-30-at-10.09.54-PM.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 flex items-center justify-center cursor-pointer" onClick={() => setIsOpen(true)}>
            <FaPlay className="text-white text-2xl lg:text-[3rem] bg-orange-500 bg-opacity-300 p-4 rounded-full" />
          </div>
        </motion.div>
      </div>

      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} contentLabel="Full Video" className="fixed inset-0 flex items-center justify-center" overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-50">
        <button onClick={() => setIsOpen(false)} className="absolute top-4 right-4 text-white text-4xl z-50"><FaTimes /></button>
        <div className="relative w-full h-full">
          <video src="https://adtobox.com/wp-content/uploads/2023/12/WhatsApp-Video-2023-11-30-at-10.09.54-PM.mp4" controls autoPlay className="w-full h-full object-cover" />
        </div>
      </Modal>

      <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-24 my-8 overflow-hidden">
        <h4>Our Values</h4>

        {loading ? (
          <motion.div className='border-b border-black py-6 mt-8' initial="hidden" animate="visible" variants={borderVariants} transition={{ duration: 1, ease: "easeInOut" }} />
        ) : (
          <>
            {renderContent('Innovation is key', 'We stay updated with industry trends to bring fresh ideas and impactful solutions. By continuously exploring new tools and techniques, we help your brand stand out and stay ahead.', '01')}
            {renderContent('Always overdeliver', 'We believe in giving more than what’s expected. Our team is dedicated to providing value at every step, ensuring that the results consistently exceed your goals. This commitment to quality means we’re focused on delivering the best for you.', '02')}
            {renderContent('Customer Satisfaction', 'Your satisfaction is our highest priority. We listen to your needs, provide transparent communication, and adapt as needed to ensure every experience with us is positive. For us, success means helping you succeed.', '03')}
          </>
        )}
      </div>
        <Team/>
        <CounterAbout/>
        <Footer/>
    </>
  );
};

export default AboutUs;
