import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import FunFactImg from "../../../assets/images/FunFact.webp";
import DidYouKnowImg from "../../../assets/images/DidYouKnow.webp";
import Insights from "../../../assets/images/Insights.webp";

const FunFact = () => {
  const facts = [
    {
      heading: "First Impressions Count",
      content: [
        "Users form an opinion about your website in 0.05 seconds. Make that half-second work with stunning visuals and fast loading!",
        "Gamify and Convert: Adding gamification (like quizzes or points) to your site increases engagement by 47%. Fun equals loyalty!",
      ],
      image: FunFactImg,
    },
    {
      heading: "Voice Search Wave",
      content: [
        "By 2025, 50% of searches will be voice-driven. Optimize for conversational keywords to capture this trend.",
        "Social Commerce Boom: 80% of Instagram users make purchase decisions based on what they see in the app. E-commerce is social!",
        "AI-Driven Recommendations: 35% of Amazon's revenue comes from AI-powered product recommendations. Personalization drives growth!",
      ],
      image: DidYouKnowImg,
    },
    {
      heading: "PPC Domination",
      content: [
        "Businesses using targeted PPC campaigns grow their leads by 54% faster compared to those that don’t. Precision pays off!",
        "The AI Factor: 61% of marketers now use AI to optimize PPC campaigns. Embrace automation to stay ahead of the curve.",
      ],
      image: Insights,
    },
  ];

  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const contentDuration = 5000; // Time each content stays visible (ms)
  const pauseDuration = 5000; // Pause between headings (ms)

  useEffect(() => {
    const currentContent = facts[currentFactIndex].content;

    const contentInterval = setInterval(() => {
      setCurrentContentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;

        if (nextIndex >= currentContent.length) {
          clearInterval(contentInterval);

          setTimeout(() => {
            setCurrentContentIndex(0); // Reset content index
            setCurrentFactIndex((prevFactIndex) => (prevFactIndex + 1) % facts.length); // Move to next slide
          }, pauseDuration);
        }
        return nextIndex < currentContent.length ? nextIndex : prevIndex;
      });
    }, contentDuration);

    return () => clearInterval(contentInterval);
  }, [currentFactIndex, facts]);

  // Calculate total animation duration for the border
  const totalAnimationDuration =
    facts[currentFactIndex].content.length * contentDuration + pauseDuration;

  return (
    <div className="bg-blue-500 p-8 rounded-3xl relative overflow-hidden">
      <div className="text-white">
        <h3 className="mb-2 text-[25px]">Insights & Industry Stats</h3>
        <div className="grid grid-cols-3 items-center gap-8">
          <div className="col-span-2">
            <div className="relative h-[120px] overflow-hidden">
              <h4 className="text-[20px] font-semibold mb-4">{facts[currentFactIndex].heading}</h4>
              <AnimatePresence mode="wait">
                <motion.div
                  key={`${currentFactIndex}-${currentContentIndex}`}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className="absolute w-full"
                >
                  <p>{facts[currentFactIndex].content[currentContentIndex]}</p>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>

          {/* Image Section */}
          <div className="relative h-[230px] w-full flex items-center justify-center">
            <AnimatePresence mode="wait">
              <motion.img
                key={currentFactIndex}
                src={facts[currentFactIndex].image}
                alt={facts[currentFactIndex].heading}
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="h-full w-auto object-contain"
              />
            </AnimatePresence>
          </div>
        </div>
      </div>

      {/* Gradient Border Animation */}
      <div
        className={`absolute bottom-0 left-0 h-[3px] bg-gradient-to-r from-orange-400 via-red-400 to-purple-400`}
        style={{
          animation: `gradientWidth ${totalAnimationDuration}ms linear infinite`,
        }}
      ></div>
    </div>
  );
};

export default FunFact;
