import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Marquee from "react-fast-marquee";

// Rating Image
import GreenRating from '../../assets/images/GreenRating.png';
import OrangeRating from '../../assets/images/OrangeRating.png';
import YellowRating from '../../assets/images/yellowRating.png';
import BlackRating from '../../assets/images/WhatsApp Image 2024-12-08 at 9.50.06 AM (1).jpeg';

// Client Review Images
import ZoyaRatingImg from '../../assets/images/client-review/Zoya Demircanli.png';
import NikkiRatingImg from '../../assets/images/client-review/Nikki Weed.png';
import RochelleRatingImg from '../../assets/images/client-review/Rochelle Horton.png';
import ChongRatingImg from '../../assets/images/client-review/Chong Mi.png';
import AyushiRatingImg from '../../assets/images/client-review/Ayushi Kapoor.png';
import MattRatingImg from '../../assets/images/client-review/Matt Ardisson.png';

// Rating Icon Image
import Trustpilot from '../../assets/images/client-review/trustpilot.svg';
import Google from '../../assets/images/client-review/google-icon.svg';
import Pintrest from '../../assets/images/client-review/pintrest.png';
import Clutch from '../../assets/images/client-review/clutch.jpg';
import Twitter from '../../assets/images/twitter.png';


const columnData = [
    {
        id: 1,
        direction: "up",
        items: [
            {
                name: "Nikki Weed",
                text: "Working with Ad2Box has been a wonderful experience, as our needs are always attended to with courtesy and urgency. He has been a breath of fresh air to our business, as we had been taken for a ride by another firm, which left us high and dry and light a pile of money. Finding Ad2Box has been a true treat to us, and I'm confident he can elevate your company just as much as he has ours. His passion is unrivaled!",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Matt Ardisson",
                text: "Very good service. Responsive and helpful. I’ve worked with many ads companies and this is the best! Very happy to continue the service!",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Simran Jaiswal",
                text: "Wonderful support and the team was available for any queries and provided quick resolution. They understood Business and was quick to resolve it. They went up and beyond to help with the business.",
                image: YellowRating,
                imageIcon: Google
            },
            {
                name: "Edward Casano",
                text: "Over the past year and a half, I've been working with Abhishek from Ad2box on managing our Google Ads account. Abhishek has been really helpful in creating new campaigns and sorting out technical issues. He's a friendly and reliable person to work with and has become an extension of our team. Looking forward to continuing our partnership and optimizing our Google Ads further.",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Rajendra Verma",
                text: "I was really impressed with the level of service that I received from the team at ad2box marketing solutions. They were always available to answer my questions and they were always willing to go the extra mile to help me. I would definitely recommend them to anyone who is looking for a digital marketing agency that provides excellent customer service.",
                image: OrangeRating,
                imageIcon: Pintrest
            },
            {
                name: "Shrey Singh",
                text: "I highly recommend them to anyone looking to improve their online marketing efforts.",
                image: BlackRating,
                imageIcon: Clutch
            },
        ],
    },
    {
        id: 2,
        direction: "down",
        items: [
            {
                name: "Candice Forbes",
                text: "I had a great time working with these guys. They really helped in stepping up my game in the market and guided me right from scratch. They use great channels for communication and also provide with new creative ideas to attract more customers for my website.",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Taurus Smith",
                text: "I have been working closely with Abhishek from Ad2Box more than two years. His team has been a reliable partner to my business managing our digital ad campaigns, troubleshooting issues and improving as performance. I highly recommend Ad2Box for their digital marketing services.",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Rohit Gupta",
                text: "I was looking for a way to increase my social media following and Ad2Box was able to help me to do that in a matter of weeks. They helped me to create engaging content and they were also able to help me to run successful social media ads. I'm so happy with the results and I would definitely recommend them to anyone looking to grow their social media following.",
                image: YellowRating,
                imageIcon: Google
            },
            {
                name: "Faizan Khan",
                text: "Working with various digital marketing agencies in the past, I've had my fair share of experiences, but none compare to the outstanding service and results delivered by this particular agency. Their approach to our campaigns has not just met but far exceeded my expectations. What sets them apart is their impeccable communication and detailed reporting, making the entire process seamless and highly effective.",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Shubham Rastogi",
                text: "We were struggling to get our email marketing campaigns to open and convert, then we hired Ad2Box and they were able to help me to improve our email marketing strategy and I'm now seeing a much better open rate and click-through rate. I would definitely recommend them to anyone looking to improve their email marketing results.",
                image: YellowRating,
                imageIcon: Twitter
            },
            {
                name: "Swati Tiwari",
                text: "The way they communicated or the way the team was handling the query was impressive.",
                image: BlackRating,
                imageIcon: Clutch
            },
        ],
    },
    {
        id: 3,
        direction: "up",
        items: [
            {
                name: "Ayushi Kapoor",
                text: "I absolutely enjoyed my experience with the team and they were able to provide high quality leads and marketing strategies that helped us boost our sales!",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Mukesh Babu",
                text: "As a startup, we had limited resources, but Ad2Box helped us maximize our PPC budget. Their team provided excellent guidance and support throughout the process. We're excited to continue working with them as we scale our business.",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Swati Tiwari",
                text: "The way they communicated or the way the team was handling the query was impressive.",
                image: BlackRating,
                imageIcon: Clutch
            },
            {
                name: "Akhilesh Gupta",
                text: "So far so good, great customer service! No matter what time..I get a solution within minutes. Love it and would always work with them. Good job team!",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Anshul Jain",
                text: "I was really disappointed with the results that I was getting from my previous digital marketing agency, but the team at Ad2Box was able to turn things around for me. They helped me to create a new marketing plan that was more effective and I'm now seeing much better results. I would definitely recommend them to anyone who is unhappy with their current digital marketing results.",
                image: OrangeRating,
                imageIcon: Pintrest
            },
            
        ],
    },
    {
        id: 4,
        direction: "down",
        items: [
            {
                name: "Zoya Demircanli",
                text: "Abhishek is so easy to work with, we are glad he’s part of our team. He has been handling our end of google Ads, and everything has been so smooth! Thanks!",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Rochelle Horton",
                text: "I have been working with Ad2Box a few months. Great customer service, prompt responses, and great listener. Troubleshoots and gets to the root of the problem for a solution.",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Neha Joshi",
                text: "I was really impressed with the level of service that I received from the team at ad2box marketing solutions. They were always available to answer my questions and they were always willing to go the extra mile to help me. I would definitely recommend them to anyone who is looking for a digital marketing agency that provides excellent customer service.",
                image: YellowRating,
                imageIcon: Google
            },
            {
                name: "Jamichael Mcclure",
                text: "Team helped me to manage complex PPC campaigns across multiple platforms was impressive. Their approach and focus on ROI have delivered outstanding results for our business. We're confident in their ability to drive continued growth. Good Work Guyz!!",
                image: YellowRating,
                imageIcon: Twitter
            },
            {
                name: "Chong Mi",
                text: "We started everything from scratch..website development, branding, ads..everything and the team did a wonderful job. So happy with their work. Highly recommended!!",
                image: GreenRating,
                imageIcon: Trustpilot
            },
            {
                name: "Aamir Khan",
                text: "Ad2Box Marketing Solutions’ efforts increased leads by 30% and improved site speed by 78%. The team delivered on time and communicated effectively throughout the project. Their passion for helping businesses and timely work were impressive.",
                image: BlackRating,
                imageIcon: Clutch
            },
        ],
    },
];


const testimonials = [
    {
        id: 1,
        name: 'Zoya Demircanli',
        title: 'Kirrin Finch',
        ratingImage: ZoyaRatingImg,
        testimonial: 'Abhishek is so easy to work with, we are glad he’s part of our team. He has been handling our end of google & Bing Ads, and everything has been so smooth! Thanks!',
    },
    {
        id: 2,
        name: 'Nikki',
        title: 'tl;dv',
        ratingImage: NikkiRatingImg,
        testimonial: 'So far so good, great customer service! No matter what time..I get a solution within minutes. Love it and would always work with them. Good job team!',
    },
    {
        id: 3,
        name: 'Rochelle Horton',
        title: 'Necklace De Amor',
        ratingImage: RochelleRatingImg,
        testimonial: 'I have been working with Ad2Box for a few months. Great customer service, prompt responses, and great listener. Troubleshoots and gets to the root of the problem for a solution.',
    },
    {
        id: 4,
        name: 'Chong Mi',
        title: 'AHS Premier',
        ratingImage: ChongRatingImg,
        testimonial: 'We started everything from scratch..website development, branding, ads..everything and the team did a wonderful job. So happy with their work. Highly recommended!! ',
    },
    {
        id: 5,
        name: 'Ayushi Kapoor',
        title: 'Goodfair',
        ratingImage: AyushiRatingImg,
        testimonial: ' I absolutely enjoyed my experience with the team and they were able to provide high quality leads and marketing strategies that helped us boost our sales!',
    },
    {
        id: 6,
        name: 'Matt Ardission',
        title: 'The Attractive Man',
        ratingImage: MattRatingImg,
        testimonial: ' Very good service. Responsive and helpful. I’ve worked with many ads companies and this is the best! Very happy to continue the service!',
    },
];


const TestimonialSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const delay = 5000;

    useEffect(() => {
        const interval = setInterval(() => {
            nextTestimonial();
        }, delay);

        return () => clearInterval(interval);
    }, [currentIndex]);

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
        );
    };

    return (
        <>
            <div className="relative mx-auto space-y-12 bg-image py-8 lg:py-28">
                <div className="relative">
                    <motion.h2
                        className="text-center text-[2rem] lg:text-[5rem] font-semibold mb-8 text-transparent bg-clip-text"
                        style={{
                            backgroundImage: "linear-gradient(90deg, #FF007F, #FFDD00, #50C878, #007FFF)",
                        }}
                        initial={{ backgroundSize: "200% 200%" }}
                        animate={{ backgroundPosition: ["100% 0%", "0% 0%"] }}
                        transition={{
                            duration: 5,
                            repeat: Infinity,
                            ease: "linear",
                        }}
                    >
                        Just amazing...
                    </motion.h2>
                </div>
                <div className="relative overflow-hidden ">
                    <div className="relative w-full h-60 ">
                        <AnimatePresence>
                            <motion.div
                                key={testimonials[currentIndex].id}
                                initial={{ opacity: 0, x: 100 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -100 }}
                                transition={{ duration: 0.5 }}
                                className="absolute inset-0 flex  items-center justify-center p-4"
                            >
                                <div className='px-[2rem] lg:px-[5rem]'>
                                    <p className="text-[1rem] lg:text-[2rem] font-semibold text-center">"{testimonials[currentIndex].testimonial}"</p>
                                    <div className='flex items-center justify-center mt-4'>
                                        <div>
                                            <img
                                                src={testimonials[currentIndex].ratingImage}
                                                alt="Rating"
                                                className="w-12 h-12 lg:w-16 lg:h-16 mr-4 rounded-full"
                                            />                                        </div>
                                        <div>
                                            <h4 className="mt-4 font-semibold">{testimonials[currentIndex].name}</h4>
                                            <p className="text-sm text-gray-500">{testimonials[currentIndex].title}</p>
                                        </div>
                                    </div>

                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                    <button
                        onClick={prevTestimonial}
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 px-4 py-2 bg-white rounded-full hover:bg-gray-200 transition ml-[1rem] lg:ml-16"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button
                        onClick={nextTestimonial}
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 px-4 py-2 bg-white rounded-full hover:bg-gray-200 transition mr-[1rem] lg:mr-16"
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            </div>
            {/* Image Marquee Section */}
            <div className='w-full bg-[#F5F5F7] h-[800px] overflow-hidden'>
                <div className='container mx-auto px-[1rem] lg:px-[4rem] pt-8 overflow-hidden'>
                    <div className="absolute  left-0 w-full h-[200px] bg-gradient-to-b from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                    {/* <div className="relative top-[0rem] left-0 w-full h-[200px] bg-gradient-to-t from-[#F5F5F7] to-transparent pointer-events-none z-10"></div> */}
                    {/* New Code */}
                    <div className="marquee-container">
                        {columnData.map((column) => (
                            <div
                                key={column.id}
                                className={`marquee-column scroll-${column.direction}`}
                            >
                                <div className="marquee-content">
                                    {/* Duplicate the items to enable seamless scrolling */}
                                    {[...column.items, ...column.items, ...column.items, ...column.items, ...column.items].map((item, index) => (
                                        <div key={index} className="marquee-card">
                                            <div className="bg-white p-8 rounded-xl card-shadow mb-8">
                                                <div className='flex items-center justify-between'>
                                                    <h4 className='mb-2 text-[14px] font-bold'>{item.name}</h4>
                                                    <img src={item.imageIcon} alt="" width={20} />
                                                </div>
                                                <img
                                                    src={item.image}
                                                    alt={`${item.name}-rating`}
                                                    className="ml-[-6px] w-[100px]"
                                                />
                                                <p className="text-[14px] mt-2">{item.text}</p>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="relative  top-[-14rem] left-0 w-full h-[200px] bg-gradient-to-t from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                </div>
            </div>

        </>
    );
};

export default TestimonialSlider;
