import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import PerformanceImg1 from '../../../assets/images/EdsPlantShop.webp';
import PerformanceImg2 from '../../../assets/images/KawaiiKart.webp';
import PerformanceImg3 from '../../../assets/images/TheAttractiveMan.webp';

const PerformanceGraph = () => {
  const images = [PerformanceImg1, PerformanceImg2, PerformanceImg3];
  return (
    <>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={2000}
      >
        <SwiperSlide>
          <img
            src={PerformanceImg1}
            alt="Performance 1"
            className="rounded-bl-3xl rounded-br-3xl"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={PerformanceImg2}
            alt="Performance 2"
            className="rounded-bl-3xl rounded-br-3xl"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={PerformanceImg3}
            alt="Performance 3"
            className="rounded-bl-3xl rounded-br-3xl"
          />
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default PerformanceGraph