import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { faPlus, faMinus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Footer from '../navigation/Footer'
import Lottie from 'lottie-react';
import animationData from '../../assets/lottie-animatin/Animation - 1735536766679.json'
import Button from './section/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from '../../services/apiService';

const Affiliate = () => {
    const items = [
        "Get performance-based payouts beyond the programme's baseline rates – the more you refer, the more you can earn, with no annual earnings cap. Find the list of current commissions rates by country.",
        "Payouts are per new user. For example, if you refer 1 new Business Plus account in the United States with 10 users, you get paid for all 10 users: USD $270. Earn for up to 300 users per account that you refer. Commissions vary by country and edition.",
        "Unlock opportunities to promote a variety of exclusive offers that give referred users savings on their subscriptions.",
    ];
    const [activeIndex, setActiveIndex] = useState(0);
      const [faqs, setFaqs] = useState([]);
    
      useEffect(() => {
        const getFaqs = async () => {
          try {
            const data = await fetchData('faqs');
            setFaqs(data);
          } catch (error) {
            console.error('Failed to fetch FAQs:', error);
          }
        };
        getFaqs();
      }, []);
    
      const handleToggle = (index) => setActiveIndex(index === activeIndex ? null : index);
    return (
        <>
            <div className='container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden  py-16 md:py-20'>
                <div className='flex items-center justify-between flex-col md:flex-row '>
                    <div>
                        <h1 className='mb-4 text-[55px] leading-tight'>Become a Google Workspace affiliate</h1>
                        <p className='text-[20px] mb-8'>Earn by promoting Gmail, Google Meet, Drive and more.</p>
                        <Button label="Join Now" link="/" padding={{ top: 5, right: 20, bottom: 5, left: 20 }} />                    </div>
                    <div>
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            style={{ width: 400, height: 400 }}
                        />
                    </div>
                </div>
            </div>
            <div className='container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden  pb-16 md:pb-20'>
                <h2 className='text-center'>How it works</h2>
                <div className='grid grid-cols-1 md:grid-cols-3 mt-12 gap-10 md:gap-32'>
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="text-orange-400 text-[30px]" />
                        <h3 className='mt-4'>Join</h3>
                        <p>Join at no charge via CJ Affiliate. You'll be notified when your application is reviewed.</p>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="text-orange-400 text-[30px]" />
                        <h3 className='mt-4'>Join</h3>
                        <p>Join at no charge via CJ Affiliate. You'll be notified when your application is reviewed.</p>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="text-orange-400 text-[30px]" />
                        <h3 className='mt-4'>Join</h3>
                        <p>Join at no charge via CJ Affiliate. You'll be notified when your application is reviewed.</p>
                    </div>
                </div>
                <div className='flex justify-center mt-12'>
                    <Button label="Join Now" link="/" padding={{ top: 5, right: 20, bottom: 5, left: 20 }} />
                </div>
            </div>
            <div className='container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden  pb-16 md:pb-20'>
                <ul className="list-disc pl-4">
                    {items.map((item, index) => (
                        <li key={index} className="mb-2 text-[18px]">
                            {item}
                        </li>
                    ))}
                </ul>
                <div className='flex justify-center mt-12 flex-col items-center'>
                    <Button label="Join Now" link="/" padding={{ top: 5, right: 20, bottom: 5, left: 20 }} />
                    <p className='mt-4 text-center'>Looking to refer only a few users? Take a look at the referral programme</p>
                </div>
            </div>
            <div className="max-w-3xl mx-auto p-4 pt-0 pb-20 md:py-20">
      <div className='text-center'>
        <h2 className="text-[2rem] lg:text-[3rem] mb-4">Affiliate FAQs</h2>
      </div>
      {faqs.map((faq, index) => (
        <div key={index} className={`border-b ${index === activeIndex ? 'shadow-lg rounded-3xl mb-4' : ''}`}>
          <div className="flex items-center justify-between p-[10px] lg:p-6 cursor-pointer" onClick={() => handleToggle(index)}>
            <h3 className="text-[20px] lg:text-[25px]">{faq.title}</h3>
            <div className={`p-[10px] w-10 h-10 flex items-center justify-center border-2 rounded-full ${index === activeIndex ? 'border-gray-400' : 'border-gray-300'}`}>
              <FontAwesomeIcon icon={activeIndex === index ? faMinus : faPlus} className="text-lg transition-transform duration-300" />
            </div>
          </div>
          <AnimatePresence>
            {activeIndex === index && (
              <motion.div className="px-6 pb-6 pt-0" initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ duration: 0.5 }}>
                <p>{faq.content}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
            <Footer />
        </>
    )
}

export default Affiliate