import React from 'react';

const Loader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
      <div className="loader animate-spin rounded-full h-16 w-16 border-t-4 border-orange-400"></div>
    </div>
  );
};

export default Loader;
