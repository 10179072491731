import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';

const IndustryServed = () => {
    const industries = [
        '{Healthcare}',
        '{Finance}',
        '{Ed-Tech}',
        '{Manufacture}',
        '{Technology & Telecom}',
        '{Gov & Non-Profit}',
        '{Travel & Tourism}',
        '{Media & Sport}',
    ];
    return (
        <>
            <div className='container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden py-20'>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-16'>
                    <div>
                        <h2 className='text-[2rem] lg:text-[3rem]'>Industries We Serve</h2>
                    </div>
                    <div>
                        <p>SEM allows your e-commerce business to appear at the top of search results,
                            even in highly competitive niches, driving immediate attention to your products
                            or services.</p>
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-4 mt-10 gap-8'>
                    {industries.map((industry, index) => (
                        <div key={index} className='bg-white border border-gray-100 p-8 rounded-2xl'>
                            <FontAwesomeIcon icon={faSnowflake} className="mb-20 text-[30px]" />
                            <h4>{industry}</h4>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}

export default IndustryServed