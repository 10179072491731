import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'; // Use this import for v7+
import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.css';
import { fetchData, getImageUrl } from '../../services/apiService';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        setLoading(true);
        let data = await fetchData('posts');
        // Sort posts by created_at in descending order
        data = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setBlogPosts(data);
      } catch (error) {
        console.error('Failed to load blog posts:', error);
      } finally {
        setLoading(false);
      }
    };

    loadBlogPosts();
  }, []);

  return (
    <div className="w-full relative overflow-hidden">
      <div className="bg-gray-100">
        <div className="mx-auto py-16 md:py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center px-[1rem] md:px-[6rem]">
            {/* Heading Section */}
            <div className='text-center md:text-left md:mb-0 mb-[20px]'>
              <h2>What's New</h2>
            </div>
            {/* Buttons Section */}
            <div className="flex justify-between md:justify-end space-x-8 ">
              <button
                className="swiper-button-prev bg-white p-2 rounded-full shadow-md"
                aria-label="Previous Slide"
              >
              </button>
              <button
                className="swiper-button-next bg-white p-2 rounded-full shadow-md"
                aria-label="Next Slide"
              >
              </button>
            </div>
          </div>

          {loading ? (
            <p className="text-center mt-8">Loading...</p>
          ) : (
            <div className="swiper-wrapper-container relative">
              <Swiper
                modules={[Navigation]}
                navigation={{
                  prevEl: '.swiper-button-prev', // Matches the className of the previous button
                  nextEl: '.swiper-button-next', // Matches the className of the next button
                }}
                slidesPerView={1.2}
                spaceBetween={20}
                pagination={{ clickable: true }}
                breakpoints={{
                  640: { slidesPerView: 2.5 },
                  768: { slidesPerView: 3.5 },
                  1024: { slidesPerView: 4.5 },
                }}
                className="mt-16 swiper-container"
              >
                {blogPosts.map((post) => (
                  <SwiperSlide key={post.id}>
                    <Link to={`/posts/${post.id}`} className="swiper-slide-content">
                      <img
                        src={getImageUrl(post.image)}
                        alt={post.title}
                        className="rounded-3xl mb-4"
                      />
                      <span>{post.created_at.slice(0, 10)}</span>
                      <h4 className="mt-6">{post.title}</h4>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog;
