import React from 'react';
import { motion } from "framer-motion";
import Footer from '../../navigation/Footer';
import PPCimg from '../../../assets/video/PPC-Home-GIf.gif';
import SEOHeroSection from './heroSection/SEOHeroSection';
import PartnerLogo from './section/PartnerLogo';
import Technology from './section/Technology';
import WhyChooseUsImage from '../../../assets/images/case-study/Kawaii-Banner-Home.webp';
import CoreElementsImage from '../../../assets/images/stat-love-brands-768x621.webp';
import PerformanceGraph from '../section/PerformanceGraph';
import ClientLogoLeft from './section/ClientLogoLeft';
import IndustryServed from './section/IndustryServed';

const gridItems = [
    {
        id: 1,
        title: "Targeted Reach",
        description:
            "Unlike traditional advertising, SEM ensures that your ads appear only to users searching for keywords relevant to your business, maximizing your ad spend efficiency.",
    },
    {
        id: 2,
        title: "Instant Visibility",
        description:
            "SEM allows your e-commerce business to appear at the top of search results, even in highly competitive niches, driving immediate attention to your products or services.",
    },
    {
        id: 3,
        title: "Data-Driven Decisions",
        description:
            "Paid search campaigns provide actionable insights into user behavior, keyword performance, and conversion data, allowing you to refine your strategy continuously.",
    },
    {
        id: 4,
        title: "Scalability",
        description:
            "Whether you're just starting out or scaling up, SEM campaigns can grow with your business, adapting to changing needs and customer demands.",
    },
];

const borderVariants = {
    animate: (index) => ({
        width: ["0%", "100%", "0%"],
        transition: {
            duration: 2,
            ease: "easeInOut",
            delay: index * 2,
            repeat: Infinity,
            repeatDelay: (gridItems.length - 1) * 2,
        },
    }),
};

const Step = ({ stepNumber, title, description }) => (
    <div className="grid grid-cols-3 relative">
        <div className="text-center">
            <img src={PPCimg} alt="PPC Step" className="mx-auto" />
        </div>
        <div className="flex flex-col items-center relative">
            <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">{stepNumber}</span>
            <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
        </div>
        <div className='block m-auto'>
            <h3 className="font-semibold leading-tight mb-4">{title}</h3>
            <p>{description}</p>
        </div>
    </div>
);

const SearchEngineOptimization = () => (
    <>
        <SEOHeroSection />
        <PartnerLogo />

        <div className='w-full'>
            <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:py-24 py-16 overflow-hidden'>
                <div className='w-full lg:w-[800px] m-auto text-center'>
                    <h2 className='mb-4 text-[2rem] lg:text-[3rem]'>Why Search Engine Marketing?</h2>
                    <p>Search Engine Marketing (SEM) combines the power of paid advertising and search engine optimization to connect your business with the right audience at the right time.</p>
                    <div className='my-12 w-full lg:w-[500px] m-auto'>
                        <img src={WhyChooseUsImage} alt="Why Choose Us" className='rounded-xl single-solution-shadow' />
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-10 mt-20">
                    {gridItems.map((item, index) => (
                        <div key={item.id} className="relative">
                            <h3 className="relative pb-4 mb-8">
                                <span>{item.id < 10 ? `0${item.id}` : item.id}</span>
                                <div className="absolute left-0 bottom-0 h-[2px] bg-gray-100 w-full"></div>
                                <motion.div
                                    className="absolute left-0 bottom-0 h-[2px] bg-black"
                                    custom={index}
                                    variants={borderVariants}
                                    animate="animate"
                                ></motion.div>
                            </h3>
                            <h4 className="text-[22px] mb-2">{item.title}</h4>
                            <p className="text-[16px]">{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='container mx-auto px-[1rem] lg:px-[4rem] pb-16 md:pb-24 overflow-hidden'>
                <div className='bg-orange-50 rounded-2xl p-8 lg:p-16'>
                    <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-20'>
                        <div>
                            <h2 className='mb-8 leading-tight text-[2rem] lg:text-[3rem]'>Core Elements of SEM Success</h2>
                            {[
                                { title: "Keyword Strategy", description: "Identifying high-intent keywords that drive relevant traffic to your site." },
                                { title: "Compelling Ad Copy", description: "Crafting concise, engaging, and clear messaging that resonates with your audience." },
                                { title: "Landing Page Optimization", description: "Ensuring that every click leads to a seamless, relevant, and conversion-focused experience." },
                                { title: "Ongoing Analysis", description: "Monitoring and refining campaigns based on performance data to improve ROI." },
                            ].map((item, idx) => (
                                <div className='mb-4' key={idx}>
                                    <h4 className='mb-2'>{item.title}</h4>
                                    <p>{item.description}</p>
                                </div>
                            ))}
                        </div>
                        <div>
                            <img src={CoreElementsImage} alt="Core Elements" />
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 mt-8 gap-8'>
                    <div className='bg-gray-50 p-8 lg:p-16 rounded-2xl'>
                        <h3 className='leading-tight mb-4'>Success Probability Calculator</h3>
                        <p className='mb-8'>
                            <span className="text-[20px] font-semibold">Purpose:</span> Engage visitors with a personalized assessment tool to estimate how successful a PPC campaign or website redesign could be for their business.
                        </p>
                        <PerformanceGraph />
                    </div>
                    <div className='bg-lime-50 p-8 lg:p-16 rounded-2xl'>
                        <h3 className="leading-tight mb-4">Proudly Partnered with These Brands</h3>
                        <ClientLogoLeft />
                    </div>
                </div>
            </div>

            <div className='w-full bg-gray-50'>
                <IndustryServed />
            </div>

            <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:py-24 py-16 overflow-hidden">
                <div className="flex justify-center mb-8 lg:mb-16">
                    <h2 className='text-[2rem] lg:text-[3rem] text-center'>How It Works?</h2>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0">
                    {/* Step 1 */}
                    <div className="text-center order-last lg:order-none">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">01</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className='block m-auto text-center lg:text-left order-first lg:order-none'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]"> Precise Keyword Targeting</h3>
                        <p>
                            We begin by identifying high-performing, low-competition keywords that align with your
                            audience’s search behavior. This ensures your ads appear at the right time for the
                            right audience.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0">
                    {/* Step 2 */}
                    <div className='block m-auto text-center lg:text-left'>
                        <h3 className="font-semibold  leading-tight my-4 text-[25px] lg:text-[2rem]">Ad Copy Creation & Testing</h3>
                        <p>
                            Compelling ad copy is designed to resonate with your audience while incorporating strong calls-to-action. We perform A/B testing to identify the most effective messaging for driving conversions.
                        </p>
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">02</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className="text-center">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0">
                    {/* Step 3 */}
                    <div className="text-center order-last lg:order-none">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">03</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className='block m-auto text-center lg:text-left order-first lg:order-none'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]">Campaign Structuring & Bid Management</h3>
                        <p>
                            Campaigns are meticulously structured to maximize reach and ROI, with smart bid strategies that allocate budgets effectively to high-value opportunities.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0 ">
                    {/* Step 4 */}
                    <div className='block m-auto text-center lg:text-left'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]">Performance Tracking & Analytics</h3>
                        <p>
                            We monitor key metrics such as click-through rates (CTR), conversion rates, and cost-per-click (CPC) to evaluate campaign performance and identify areas for improvement.
                        </p>
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">04</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className="text-center">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative mb-8 lg:mb-0">
                    {/* Step 5 */}
                    <div className="text-center order-last lg:order-none">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">05</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className='block m-auto text-center lg:text-left order-first lg:order-none'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]">Ongoing Optimization & Scaling</h3>
                        <p>
                            Through continuous analysis, we refine ad targeting, adjust bids, and scale successful campaigns to reach broader audiences while maintaining efficiency.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 relative  mb-8 lg:mb-0">
                    {/* Step 6 */}
                    <div className='block m-auto text-center lg:text-left'>
                        <h3 className="font-semibold leading-tight my-4 text-[25px] lg:text-[2rem]">Retargeting & Audience Segmentation</h3>
                        <p>
                            Advanced retargeting strategies are employed to re-engage previous visitors, paired with audience segmentation to personalize ad experiences and boost engagement.
                        </p>
                    </div>
                    <div className="flex flex-col items-center relative order-first lg:order-none">
                        <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">06</span>
                        {/* Vertical Line */}
                        <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                    </div>
                    <div className="text-center">
                        <img src={PPCimg} alt="" className="mx-auto" />
                    </div>
                </div>
            </div>

        </div>
        <Technology />
        <Footer />
    </>
);

export default SearchEngineOptimization;
