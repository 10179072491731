import React from 'react'
import { motion } from 'framer-motion';
import Partner1 from '../../../assets/images/partners/Amazon.png';
import Partner2 from '../../../assets/images/partners/Apple.png';
import Partner3 from '../../../assets/images/partners/Bing.png';
import Partner4 from '../../../assets/images/partners/Designrush.png';
import Partner5 from '../../../assets/images/partners/Digiperform.png';
import Partner6 from '../../../assets/images/partners/Fiverr.png';
import Partner7 from '../../../assets/images/partners/Google.png';
import Partner8 from '../../../assets/images/partners/HubSpot.png';
import Partner9 from '../../../assets/images/partners/LinkedIn.png';
import Partner10 from '../../../assets/images/partners/Meta.png';
import Partner11 from '../../../assets/images/partners/SEMrush.png';
import Partner12 from '../../../assets/images/partners/Shopify.png';
import Partner13 from '../../../assets/images/partners/Sproutsocial.png';
import Partner14 from '../../../assets/images/partners/Upwork.png';
import Partner15 from '../../../assets/images/partners/Zoho.png';

const PartnerLogo = () => {
    return (
        <>
            <motion.div className="overflow-hidden my-16">
                <motion.div
                    className="flex gap-20 justify-center items-center"
                    animate={{ x: "-100%" }}
                    transition={{ repeat: Infinity, duration: 30, ease: "linear" }}
                >
                    {[...Array(3)].flatMap(() =>
                        [
                            Partner1,
                            Partner2,
                            Partner3,
                            Partner4,
                            Partner5,
                            Partner6,
                            Partner7,
                            Partner8,
                            Partner9,
                            Partner10,
                            Partner11,
                            Partner12,
                            Partner13,
                            Partner14,
                            Partner15,

                        ].map((src, index) => (
                            <div
                                key={`${index}-${Math.random()}`}
                                className="flex-none w-[10.67%] md:w-[10.67%] sm:w-[33.33%] xs:w-[50%]"
                            >
                                <img src={src} alt={`Partner${index + 1}`} className="w-full h-auto bg-" />
                            </div>
                        ))
                    )}
                </motion.div>
            </motion.div>



        </>
    )
}

export default PartnerLogo