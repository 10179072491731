import React from 'react';
import { TypeAnimation } from 'react-type-animation';
const TrustedBySection = () => {
  return (
    <>
      <h2 className='text-[2rem] md:text-[3rem]'>Trusted by</h2>
      <TypeAnimation
    preRenderFirstString={true}
    sequence={[
      1000,
      'Leading Brands', 
      2000,
      'Industry Leaders',
      2000,
      'Successful Startups',
      2000,
      'Advertising Professionals',
      2000,
      'Growing Businesses',
      1000,
    ]}
    speed={10}
    className='trusted-typing-text text-[2rem] md:text-[3rem]'
    repeat={Infinity}
  />
    </>
  );
};

export default TrustedBySection;
