import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';

const PPCServiceNav = () => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <div className="relative grid grid-cols-1 md:grid-cols-2 items-center border-t-[3px] border-b-[3px]">
        {/* Animated Gradient Border Top */}
        <div className="absolute top-[-6px] left-0 w-full h-[6px] bg-gradient-to-r animate-move"></div>

        {/* Content */}
        <div className="relative z-10 py-6">
          <h4 className="text-lg font-semibold text-center md:text-left">Discover More</h4>
          <ul className="flex flex-wrap justify-center md:justify-start gap-3 md:gap-6 mt-4">
            {[
              { path: '/solutions/seo', label: 'SEO' },
              { path: '/solutions/smm', label: 'SMM' },
              { path: '/solutions/marketing-ads', label: 'Marketing Ads' },
              { path: '/solutions/display-ads', label: 'Display Ads' },
            ].map(({ path, label }) => (
              <li
                key={path}
                className={`border-2 px-[20px] py-[5px] rounded-3xl hover-service-nav ${
                  isActive(path) ? 'bg-black text-white' : 'border-[#4b4b4b]'
                }`}
              >
                <Link to={path}>{label}</Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Arrow */}
        <div className="hidden md:block">
          <FontAwesomeIcon icon={faArrowDown} className="float-right rotate-45 text-[7rem] animate-bounce-arrow" />
        </div>

        {/* Animated Gradient Border Bottom */}
        <div className="absolute bottom-[-6px] left-0 w-full h-[6px] bg-gradient-to-r animate-move"></div>
      </div>

      {/* CSS for Gradient Animation */}
      <style jsx>{`
        .animate-move {
          background: linear-gradient(
            90deg,
            #ff6b6b,
            #f06595,
            #845ef7,
            #5c7cfa,
            #339af0,
            #22b8cf,
            #20c997,
            #51cf66,
            #94d82d,
            #fcc419,
            #ff922b,
            #ff6b6b
          );
          background-size: 300% 300%;
          animation: moveGradient 5s linear infinite;
        }

        @keyframes moveGradient {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 100% 50%;
          }
        }

        .animate-bounce-arrow {
          transform: rotate(45deg);
          animation: bounceArrow 2s infinite;
        }

        @keyframes bounceArrow {
          0%,
          100% {
            transform: translateY(0) rotate(45deg);
          }
          50% {
            transform: translateY(-20px) rotate(45deg);
          }
        }
      `}</style>
    </>
  );
};

export default PPCServiceNav;
